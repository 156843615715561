import { useState, useCallback, useEffect } from "react";

const Loading = ({ isVisible }) => {
  const [dots, setDots] = useState(0);

  const updateDots = useCallback(() => {
    if (dots === 3) setDots(0);
    else setDots(dots + 1);
  }, [dots]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateDots();
    }, 500);

    return () => clearInterval(intervalId);
  }, [updateDots]);

  return (
    <div
      className={`fixed top-0 left-0 w-full h-full bg-black z-50 flex justify-center opacity-90 ${
        isVisible ? "" : "hidden"
      }`}
    >
      <div className="relative flex items-center justify-center ">
        <div className="w-[15vh] h-[15vh] border-b-4 border-[#1B2646] rounded-full animate-spin"></div>
        <span className="absolute text-white">
          Carregando{".".repeat(dots)}
        </span>
      </div>
    </div>
  );
};

export default Loading;
