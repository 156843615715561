import api from "./index";

export const utilAPi = {
    getSetor: async (token) => {
        return api.get("/Util/Setores", token);
    },
    getDivisoes: async (token) => {
        return await api.get("/Util/Divisoes");
    },
    postResultados: async (data, token) => {
        return await api.post("/Dash/Resultados", data, token);
    },
    getParametros: async (token) => {
        return await api.post("/Dash/ObterParametros", null, token);
    }
}