import { useMemo, useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUserToken } from "../../features/user";
import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import { columnType } from "../../components/Consulta/typing";
import { usuarioApi } from "../../utility/api/usuarioAPI";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { utilAPi } from "../../utility/api/utilAPI";
import Header from "../../routes/Layout/Header";
import { selectUser, userloggedIn } from "../../features/user";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Index = () => {
  const token = useSelector(selectUserToken);
  const user = useSelector(selectUser);
  const form2 = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [parametros, setParametros] = useState(false);
 
  const filtros = [
    {
      label: "Nome",
      placeholder: "Pesquise por qualquer coluna",
      name: "queryNome",
    },
  ];

  const colunas = [
    {
      label: "Nome",
      prop: "nome",
      width: "25%",
    },
    {
      label: "Setor",
      prop: "setor",
      width: "25%",
    },
    {
      label: "Divisão",
      prop: "divisao",
      width: "25%",
    },
    {
      label: "Cargo",
      prop: "cargo",
      width: "25%",
    },
  ];

  const fetchParametros = useCallback(async () => {
    let result = await utilAPi.getParametros(token);
    setParametros(result.data);
  }, [token]);
  useEffect(() => {
    fetchParametros();
  }, [fetchParametros]);
  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: usuarioApi.sugestaoAvaliador,
      },
    };
  }, []);

  const cardOptions = useMemo(() => {
    return {
      config: {
        hasAlert: (item) => item.hasAlert,
        isVisible: false,
      },
      editar: {
        label: "Editar",
        action: () => console.log(""),
        hasAlert: (item) => item.hasAlert,
      },
    };
  }, []);

  const consulta = useConsulta(token, events, cardOptions);
  const { items, pagina } = consulta;

  const [itemsSelected, setItemsSelected] = useState([]);
  const [isConfirmationMode, setConfirmationMode] = useState(
    user.permissao.sugestaoEnviada
  );

  const [itemChange, setItemChange] = useState(null);
  const [isDataSent, setDataSent] = useState(user.permissao.sugestaoEnviada);
  const [nomeSuperior, setNomeSuperior] = useState("Nome Superior");
  const [emailSuperior, setEmailSuperior] = useState(
    "email_superior@valgrouco.com"
  );

  const take = 4;
  const getItem = useCallback(() => {
    return itemsSelected.slice(pagina * take, pagina * take + take);
  }, [itemsSelected, pagina]);

  const fetchSugestoesEnviadas = useCallback(async () => {
    let result = await usuarioApi.avaliadores(
      {
        page: 0,
        take: 100
      },
      token
    );

    if (user.permissao.sugestaoEnviada) {
      setItemsSelected(result.data.records);
    } else {
      setItemsSelected([]);
    }
  }, [token, user.id, user.permissao.sugestaoEnviada]);

  useEffect(() => {
    fetchSugestoesEnviadas();
  }, [fetchSugestoesEnviadas]);

  const onCardSelected = (item, isSelected) => {
    const isItemChange = itemChange != null;

    if (isItemChange && isSelected) {
      const myArray = itemsSelected.filter(function (obj) {
        return obj.avaliadorId !== itemChange.avaliadorId;
      });
      setItemsSelected([...myArray, item]);
      setItemChange(null);
      setConfirmationMode(true);
      return;
    }

    if (isItemChange && !isSelected) {
      return;
    }

    if (isSelected) {
      if (itemsSelected.length === parametros.qtdeSugestaoMax) {
        Swal.fire({
          icon: "error",
          title: "Seleção inválida!",
          text: "Número máximo de avaliadores já selecionado.",
        });
        return;
      }

      setItemsSelected([...itemsSelected, item]);
    } else {
      const myArray = itemsSelected.filter(function (obj) {
        return obj.avaliadorId !== item.avaliadorId;
      });
      setItemsSelected(myArray);
    }
  };

  const Cancel = () => {
    setItemsSelected([]);
  };

  const Confirm = () => {
    if (itemsSelected.length < parametros.qtdeSugestaoMin) {
      Swal.fire({
        icon: "error",
        title: "Seleção inválida!",
        text: `Necessário selecionar número mínimo de avaliadores (${parametros.qtdeSugestaoMin}).`,
      });
      return;
    }

    if (itemsSelected.length > parametros.qtdeSugestaoMax) {
      Swal.fire({
        icon: "error",
        title: "Seleção inválida!",
        text: `Necessário selecionar número máximo de avaliadores (${parametros.qtdeSugestaoMax}).`,
      });
      return;
    }

    navigate("?page=0");
    setConfirmationMode(true);
  };

  const Change = (item) => {
    setItemChange(item);
    setConfirmationMode(false);
  };

  const CancelChange = (item) => {
    setItemChange(null);
    setConfirmationMode(true);
  };

  const Remove = (item) => {
    if (itemsSelected.length - 1 < parametros.qtdeSugestaoMin) {
      Swal.fire({
        icon: "error",
        title: "Exclusão abortada!",
        text: `Necessário selecionar número mínimo de avaliadores (${parametros.qtdeSugestaoMin}).`,
      });
      return;
    }

    if (itemsSelected.length <= 1) {
      Swal.fire({
        icon: "error",
        title: "Exclusão abortada!",
        text: "Necessário ter pelo menos um avaliador selecionado para enviar.",
      });
      return;
    }

    const myArray = itemsSelected.filter(function (obj) {
      return obj.avaliadorId !== item.avaliadorId;
    });
    setItemsSelected(myArray);
  };

  const SentData = async () => {
    if (itemsSelected.length < parametros.qtdeSugestaoMin) {
      Swal.fire({
        icon: "error",
        title: "Seleção inválida!",
        text: `Necessário selecionar número mínimo de avaliadores (${parametros.qtdeSugestaoMin}).`,
      });
      return;
    }

    if (itemsSelected.length > parametros.qtdeSugestaoMax) {
      Swal.fire({
        icon: "error",
        title: "Seleção inválida!",
        text: `Necessário selecionar número máximo de avaliadores (${parametros.qtdeSugestaoMax}).`,
      });
      return;
    }

    Swal.fire({
      title: "Deseja Continuar?",
      text: "Enviar sua escolha de sugestão é uma ação PERMANENTE e NÃO PODE SER DESFEITA.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "ENVIAR",
      cancelButtonText: "Cancelar",
      reverseButtons: false,
    }).then(async (resultConfirm1) => {
      const textConfirm =
        "Você está enviando sua sugestão para o superior " +
        user.nomeSuperior +
        " com o email, " +
        user.emailSuperior;

      if (resultConfirm1.isConfirmed) {
        Swal.fire({
          title: "Deseja Continuar?",
          text: textConfirm,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "ENVIAR",
          cancelButtonText: "Cancelar",
          reverseButtons: false,
        }).then(async (resultConfirm2) => {
          if (resultConfirm2.isConfirmed) {
            const avaliadoresData = itemsSelected.map((i) => i.avaliadorId);
            const result = await usuarioApi.escolherAvaliadores(
              avaliadoresData,
              token
            );

            if (result.hasError) {
              Swal.fire({
                icon: "info",
                title: result.error?.response?.data?.notifications.length > 0
                  ? result.error?.response?.data?.notifications[0]
                  : "",
              });
              return;
            } else {
              const newUser = {
                ...user,
                permissao: {
                  ...user.permissao,
                  sugestaoEnviada: true,
                },
              };
              dispatch(
                userloggedIn({
                  isValid: true,
                  jwt: newUser,
                  message: null,
                  token: token,
                })
              );
              setDataSent(true);
              Swal.fire({
                icon: "success",
                title: "Sua sugestão de avaliadores foi enviada com sucesso!",
              });
            }
          }
        });
      }
    });
  };
  const GoBackSelection = () => {
    setConfirmationMode(false);
  };
  console.log("isConfirmationMode", isConfirmationMode);
  if (!isConfirmationMode && items.recordsTotal >= 0) {
    return (
      <>
        <Header />
        <div className="flex flex-col items-center">
          <div className="w-full">
            <Consulta
              title="Selecione seus avaliadores"
              subtitle="Importante: Cada Cliente/Fornecedor avaliará no máximo 5 pessoas. Após atingir esse limite, o Cliente/Fornecedor não aparecerá na lista abaixo. Neste caso, você deverá indicar outra opção."
              colunas={colunas}
              filtros={filtros}
              consulta={consulta}
              allowSelectLine={true}
              onCardSelected={onCardSelected}
              checkItemSelection={(item) =>
                itemsSelected &&
                itemsSelected.find((i) => i.avaliadorId === item.avaliadorId) !=
                null
              }
            >
              {itemChange == null && (
                <div className="w-full mt-2 flex mx-auto ml-0">
                  <div className="flex">
                    <button
                      className="py-4 px-20 bg-[#187733] text-white rounded-md mr-6"
                      onClick={Confirm}
                    >
                      OK
                    </button>
                    <button
                      className="py-4 px-14 bg-[#9B9B9B] text-white rounded-md"
                      onClick={Cancel}
                    >
                      Cancelar
                    </button>
                  </div>
                  <div className="ml-4 ">
                    <div className="text-[#1B2646] font-semibold">Quantidade mínima de sugestão: {parametros.qtdeSugestaoMin}</div> 
                    <div className="text-[#1B2646] font-semibold">Quantidade máxima de sugestão: {parametros.qtdeSugestaoMax}</div>
                  </div>
                </div>
              )}
              {itemChange != null && (
                // <div className="absolute bottom-[60px] w-[92%] flex justify-end px-[74px] bg-white py-4 border border-[#ECECEC] rounded-md">
                //   <button
                //     className="py-5 px-20 bg-[#9B9B9B] text-white rounded-md"
                //     onClick={CancelChange}
                //   >
                //     Cancelar
                //   </button>
                // </div>
                <button
                  className="py-4 px-14 bg-[#9B9B9B] text-white rounded-md"
                  onClick={CancelChange}
                >
                  Cancelar
                </button>
              )}
            </Consulta>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="flex flex-col items-center">
        <Consulta
          title="Escolha sua sugestão de avaliadores..."
          colunas={colunas}
          consulta={{
            isLoading: false,
            form: form2,
            items: {
              records: getItem(),
              recordsTotal: itemsSelected.length,
              take,
              recordsFiltered: take,
            },
            pagina,
            onSearch: consulta.onSearch,
            cardOptions: {
              config: {
                hasAlert: (item) => item.hasAlert,
                isVisible: !user.permissao.sugestaoEnviada,
              },
              editar: {
                label: "Alterar",
                action: (item) => Change(item),
                hasAlert: (item) => item.hasAlert,
              },
              excluir: {
                label: "Excluir",
                action: (item) => Remove(item),
                hasAlert: (item) => item.hasAlert,
              },
            },
          }}
          allowSelectLine={false}
        >
          {items.recordsTotal > 0 && (
            <div className="flex mx-auto">
              <button
                disabled={isDataSent}
                className={
                  "py-4 px-20 text-white rounded-md mr-6 " +
                  (!isDataSent ? " bg-[#187733]" : "bg-[#9B9B9B] opacity-40")
                }
                onClick={SentData}
              >
                Enviar
              </button>
              <button
                disabled={isDataSent}
                className={
                  "py-4 px-20 bg-[#9B9B9B] text-white rounded-md" +
                  (!isDataSent ? "" : " opacity-40")
                }
                onClick={GoBackSelection}
              >
                Selecionar avaliadores
              </button>
            </div>
          )}

          {isDataSent && (
            <div className="absolute bottom-[15px] w-[90%] flex justify-between px-[74px] bg-white py-4 border border-[#ECECEC] rounded-md">
              <p className="text-[#1B2646] font-medium text-[15px]">
                Sua sugestão de avaliadores foi enviada para o superior{" "}
                <b>{user.nomeSuperior}</b>
              </p>
            </div>
          )}
        </Consulta>


      </div>
    </>
  );
};
export default Index;
