import axios from "axios";
import qs from "qs";

const getServer = () => process.env.REACT_APP_API_URL;

const httpMethod = {
  Get: "get",
  Post: "post",
  Put: "put",
  Delete: "delete",
  Patch: "patch",
};

const baseHttpRequest = async (method, url, query, body, config) => {
  let server = getServer();
  try {
    let queryString = qs.stringify(query);
    if (queryString) queryString = "?" + queryString;

    const response = await axios({
      method: method,
      ...config,
      url: server + url + queryString,
      data: body,
    });
    return response;
  } catch (error) {
    return {
      hasError: true,
      error,
    };
  }
};

const api = {
   get: async (url, query, token) => {
    let config = {
      "content-type": "text/json",
      headers: {},
    };

    if (token) {
      config.headers = {
        token: token,
      };
    }

    return baseHttpRequest(httpMethod.Get, url, query, null, config);
  },
  put: async (url, data, token) => {
    let config = {
      headers: {},
    };

    if (token) {
      config.headers = {
        token: token,
      };
    }

    return baseHttpRequest(httpMethod.Put, url, null, data, config);
  },
  patch: async (url, data, token) => {
    let config = {
      headers: {},
    };

    if (token) {
      config.headers = {
        token: token,
      };
    }

    return baseHttpRequest(httpMethod.Patch, url, data, null, config);
  },
  post: async (url, data, token, contentType="application/json") => {
    let config = {
      headers: {
        "content-type": contentType, 
      },
    };

    if (token) {
      config.headers = {
        token: token,
      };
    }

    return baseHttpRequest(httpMethod.Post, url, null, data, config);
  },
  delete: async (url, data, token) => {
    let config = {
      headers: {},
    };

    if (token) {
      config.headers = {
        token: token,
      };
    }

    return baseHttpRequest(httpMethod.Delete, url, data, null, config);
  },
};

export default api;
