import { NavLink } from "react-router-dom";
const MenuImage = ({ configMenu }) => {
  return (
    <div>
      {configMenu.map((menu, index) => (
        <div key={index} className="mb-16 h-6 flex justify-center items-center">
          <NavLink to={menu.to && `${menu.to}`} onClick={menu.actionImage} className="w-1/2" >
            <img
              alt={menu.alt}
              src={menu.icon}
              width={menu.width}
              height={menu.height}
              className="cursor-pointer"
            />
          </NavLink>
        </div>
      ))}
    </div>
  );
};
export default MenuImage;
