import { useMemo, useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUserToken } from "../../features/user";
import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import Dropdown from "../../components/Dropdown";
import { processoAPI } from "../../utility/api/processoAPI";
import { useSetores } from "../../hooks/useSetores";
import { loginApi } from "../../utility/api/loginApi";

const Index = () => {
  const token = useSelector(selectUserToken);
  const setores = useSetores(token);
  const [permissoes, setPermissoes] = useState('');

  const filtros = [
    {
      label: "Nome",
      placeholder: "Pesquise por qualquer coluna",
      name: "queryNome",
    },
  ];

  const colunas = [
    {
      label: "Nome",
      prop: "nome",
      width: "16.00%",
    },
    {
      label: "E-mail",
      prop: "email",
      width: "24.00%",
    },
    {
      label: "Setor",
      prop: "centroCusto",
      width: "10.00%",
    },
    {
      label: "Unidade",
      prop: "unidade",
      width: "10.00%",
    },
    {
      label: "Divisão",
      prop: "unidade",
      width: "10.00%",
    },
    {
      label: "Cargo",
      prop: "cargo",
      width: "12.00%",
    },
    {
      label: "Superior",
      prop: "superiorImediato",
      width: "16.00%",
    },
  ];

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: processoAPI.visualizarCarregados,
      },
    };
  }, []);

  const cardOptions = useMemo(() => {
    return {
      config: {
        hasAlert: (item) => item.hasAlert,
        isVisible: false,
      },
    };
  }, []);

  const fetchParametros = useCallback(async () => {
    let result = await loginApi.permissoes(token);
    setPermissoes(result.data);
  }, [token]);

  useEffect(() => {
    fetchParametros();
  }, [fetchParametros]);

  const consulta = useConsulta(token, events, cardOptions);
  const [showAddCategoria, setShowAddCategoria] = useState(false);
  const [showEditCategoria, setShowEditCategoria] = useState(false);

  return (
    <div className="d-f0:pl-10 d-f2:pl-0">
      <Consulta
        title="Dados de Importação"
        colunas={colunas}
        filtros={filtros}
        consulta={consulta}
      >
        <div className="font-semibold">
          <div className="text-[#187733]">Quantidade importada: {permissoes.qtdTotal}</div>
          <div className={permissoes.qtdPendentes? 'text-red-600' : 'text-[#187733]'}>Quantidade pendente: {permissoes.qtdPendentes}</div>
        </div> 
      </Consulta>
    </div>
  );
};

export default Index;
