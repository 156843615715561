import imageClose from "../../assets/png/fechar-filtro.png";

const Header = ({ title, onClose }) => {
    return (
        <>
            <div className="w-full h-[55px] flex items-center pl-8 font-bold text-xl">
                <div>{title}</div>
                <button
                    type="button"
                    className="absolute top-[15px] right-[15px] cursor-pointer"
                    onClick={onClose}
                >
                    <img
                        alt="Close"
                        src={imageClose}
                        className="w-[26px] h-[26px]"
                    />
                </button>
            </div>
            <div className="relative left-1 w-[99%] border-t-[1px] border-[#B3B3B3] " />
        </>
    );
}
export default Header