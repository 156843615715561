import Filtro from "./Filtro";
import FecharFiltro from "../../../assets/png/fechar-filtro.png";
const Filtros = ({
  form,
  configuration,
  isFiltroVisible,
  toggleFiltro,
  search,
}) => {
  const { handleSubmit } = form;

  return (
    isFiltroVisible && (
      <form
        onSubmit={handleSubmit(search)}
        className="relative w-full flex items-center border bg-white text-[#FFF] mt-10 rounded-lg m-f1:flex-col t-f1:flex-row m-f1:px-[4%] t-f1:px-0 m-f1:pt-[4%] t-f1:pt-0 t-f1:h-[150px] m-f1:h-[270px]"
      >
        <button
          type="button"
          className="absolute top-[20px] right-[2%] cursor-pointer"
          onClick={() => toggleFiltro(false)}
        >
          <img 
            alt="Fechar filtro"
            src={FecharFiltro}
            className="w-[26px] h-[26px]"
          />
        </button>
        {configuration.map((filtro, index) => (
          <Filtro
            key={index}
            filtro={filtro}
            form={form}
          />
        ))}
      </form>
    )
  );
};

export default Filtros;
