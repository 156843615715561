import IconeExcluir from "../../assets/png/excluir-icone.png";

const FileContainer = ({fileContainer}) => {
  const { arquivos, adicionar, removerArquivo, fileInputRef, multiple } = fileContainer;
  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        multiple = {multiple}
        onChange={() => {
          adicionar(fileInputRef.current.files);
        }}
      />
      <div>
        
        <div className="px-5 overflow-auto max-h-[160px]">
          {arquivos.map((arquivo, index) => (
            <div className="border-b flex items-center py-2 px-4" key={index}>
              <button
                type="button"
                className="mr-3"
                onClick={() => removerArquivo(index)}
              >
                <img
                  className="max-h-8"
                  alt="Apagar arquivo"
                  src={IconeExcluir}
                />
              </button>
              <span className="underline italic font-bold text-sm">{arquivo.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FileContainer;
