import { NavLink, useNavigate } from "react-router-dom";
import MenuHeader from "../../routes/Layout/Header";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/user";
import Swal from "sweetalert2";

const Avaliado = () => {

    const user = useSelector(selectUser);
    const navigate = useNavigate();

    const redirect = () => {

        if (user.permissao.sugestaoEnviada) {
            Swal.fire({
                icon: "info",
                title: "Sugestão enviada",
                text: `Sua sugestão de avaliadores foi enviada para o superior ${user.nomeSuperior}.`
            });

            return;
        }

        navigate("/SugestaoAvaliadores")
    }

    return (
        <>
            <MenuHeader />
            <div className="w-full h-screen flex flex-col items-center justify-center">
                <div className="text-lg font-semibold text-[#1B2646]">Aguardando a escolha de sugestões de avaliadores...</div>
                <button className="bg-[#1B2646] text-white px-10 py-4 mt-4 rounded-md hover:bg-[#25335c]" onClick={redirect}>Selecionar Avaliadores</button>
            </div>
        </>
    );
}
export default Avaliado;