import { useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux";
import { userLoggedOut } from "../../features/user";

const Logout = () => {    
    const navigate = useNavigate();
    const dispath = useDispatch();
    useEffect(()=>{
        dispath(userLoggedOut)
        navigate("/Login")
    },[dispath, navigate]);
    return <></>
}
export default Logout;
