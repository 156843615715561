import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import Body from "../../components/Modal/body";
import Modal from "../../components/Modal/Modal";
import FileContainer from "../../components/FileContainer";
import useFileContainer from "../../components/FileContainer/useFileContainer";
import Button, { buttonColor } from "../../components/Button";

import CancelarButton from "../../components/CancelarButton";
import { uploadAPI } from "../../utility/api/uploadAPI";
import { selectUserToken } from "../../features/user";
import Swal from "sweetalert2";

const ImportExcel = ({ onFinished, onClose }) => {
  const [file, setFile] = useState(false);
  const token = useSelector(selectUserToken);
  const navigate = useNavigate();

  const form = useForm({
    defaultValues: {
      document: null,
    },
  });

  const fileContainer = useFileContainer({
    multiple: false,
    extensions: [".xls", ".xlsx"],
  });

  const { fileInputRef, openFileSearchModal, removerArquivo } = fileContainer;

  const onSubmit = useCallback(async () => {
    let file = fileInputRef.current.files[0];
    console.log(file);
    if (file === undefined) {
      Swal.fire({
        icon: "info",
        title: "Importe o arquivo primeiro.",
      });
    } else {
      uploadAPI
        .uploadXLS(file, token)
        .then((response) => {
          if (response.ok) {
            Swal.fire({
              icon: "success",
              title: "Importação efetuada com sucesso!",
            });
            navigate("/pendencia/consulta");
            removerArquivo(file);
          } else {
            response.json().then((json) => {
              console.log(json);
              Swal.fire({
                icon: "error",
                title: json.notifications[0],
              });
            });
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Arquivo no formato inválido!",
          });
        });
    }
  }, [fileInputRef, navigate, token]);

  const onCloseModal = () => {
    navigate("/");
  };

  return (
    <Modal title="Importar Documentos" onClose={onClose} width="w-[50%]">
      <Body>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="grid grid-col-4 gap-4"
        >
          <div className="col-span-4">
            <Button
              color={buttonColor.darkBlue}
              onClick={() => {
                openFileSearchModal();
              }}
            >
              Procurar Arquivo
            </Button>
          </div>
          <div className="col-span-4">
            <FileContainer fileContainer={fileContainer} />
          </div>
          <div className="col-span-1">
            <CancelarButton form={form} onReturn={onClose} />
          </div>
          <div className="col-span-2"></div>
          <div className="col-span-1 flex justify-end">
            <Button color={buttonColor.darkGreen} type="submit">
              Importar
            </Button>
          </div>
        </form>
      </Body>
    </Modal>
  );
};
export default ImportExcel;
