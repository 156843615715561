import { useMemo, useState, useForm } from "react";
import { useSelector } from "react-redux";
import { selectUserToken } from "../../features/user";
import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import { pedenciaApi } from "../../utility/api/pedenciaAPI";
import ResolverPendencia from "./ResolverPendencia";
import informacaoNaoCadastradaImg from "../../assets/png/pendencia-nao-encontrada.png";

const Index = () => {
  const token = useSelector(selectUserToken);
  const [showResolverPendencia, setShowResolverPendencia] = useState(false);
  const [itemSelected, setItemSelected] = useState(false);


  const colunas = [
    {
      label: "Nome",
      prop: "nome",
      width: "16%"
    },
    {
      label: "E-mail",
      prop: "email",
      width: "26%"
    },
    {
      label: "Unidade",
      prop: "unidade",
      width: "10%"
    },
    {
      label: "Sup Imediato",
      prop: "superiorImediato",
      width: "16%"
    },
    {
      label: "E-mail do superior",
      prop: "emailSuperior",
      width: "16%"
    },
    {
      label: "Validações",
      prop: "validacoes",
      width: "16%"
    },
  ];

  const cardOptions = useMemo(() => {
    return {
      config: {
        hasAlert: (item) => item.hasAlert,
      },
      editar: {
        label: "Resolver",
        action: (item) => {
          setShowResolverPendencia(true)
          setItemSelected({ ...item })
        },
        hasAlert: (item) => item.hasAlert,
      },
      excluir: {
        label: "Excluir",
        questionText: "Tem certeza que deseja excluir? Essa pendência ainda não foi resolvida!",
        successText: "Pendência excluída.",
        fetchFunction: (id, token) => pedenciaApi.excluir(id, token),
      }
    };
  }, []);

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: pedenciaApi.listar
      },
    };
  }, []);

  const consulta = useConsulta(token, events, cardOptions);
  const { onSearch } = consulta;

  return (
    <div className="d-f0:pl-[40px] d-f3:pt-[10px]">
      <Consulta
        title="Pendências"
        colunas={colunas}
        consulta={consulta}
        configImg={informacaoNaoCadastradaImg}
      />
      {showResolverPendencia && (<ResolverPendencia item={itemSelected} token={token} onClose={() => {
        setShowResolverPendencia(false);
        onSearch();
      }} />)}
    </div>
  );
}
  export default Index;
