import api from "./index";

export const pedenciaApi = {
    listar: async (data, token) => {
        return await api.post("/Pendencia/Listar", data, token);
    },
    excluir: async (id, token) => {
        return await api.delete(`/Pendencia/Remover/${id}`, null, token);
    },
    resolver: async (data, token) => {
        return await api.put(`/Pendencia/Resolver`, data, token);
    }
}