import React, { useState } from "react";

import iconOpenMenu from "../../../assets/png/chevron-double-right.png";
import iconDashBoard from "../../../assets/png/Home.png";
import iconModules from "../../../assets/png/modules.png";
import iconModulesBlue from "../../../assets/png/modules-blue.png";

import Modulo from "./Modulo";
import SubMenu from "./SubMenu";
import MenuText from "./MenuText";
import MenuImage from "./MenuImage";
import HeaderMenu from "./HeaderMenu";

const MenuLeft = () => {
  const [isVisibleMenu, setIsVisibleShowMenu] = useState(false);
  const [isVisibleSubMenuFuncionalidades, isSetVisibleSubMenuFuncionalidades] =
    useState(false);

  const configMenu = [
    {
      title: "Home",
      icon: iconDashBoard,
      to: "/home",
      alt: "",
      actionImage: () => {
        setIsVisibleShowMenu(false);
        isSetVisibleSubMenuFuncionalidades(false);
      },
      width: "",
      height: "",
    },
    {
      title: "Funcionalidades",
      icon: iconModules,
      to: false,
      alt: "",
      actionImage: () => {
        setIsVisibleShowMenu(true);
        isSetVisibleSubMenuFuncionalidades(!isVisibleSubMenuFuncionalidades);
      },
      actionText: () => {
        isSetVisibleSubMenuFuncionalidades(!isVisibleSubMenuFuncionalidades);
      },
      width: "",
      height: "",
    },
  ];

  const closeSubMenu = () => {
    setIsVisibleShowMenu(false);
    isSetVisibleSubMenuFuncionalidades(false);
  };

  return (
    <div>
      <div className="fixed flex flex-row z-20 pt-14">
        <div className="bg-[#1B2646] z-30 h-screen w-12 absolute left-0">
          <div className="mt-4 mb-12 h-6">
            {!isVisibleMenu && (
              <button
                className="h-6 w-6 flex flex-row justify-center items-center mx-auto"
                onClick={() => {
                  setIsVisibleShowMenu(!isVisibleMenu);
                }}
              >
                <div className="h-full w-full">
                  <img alt="Abrir Menu" src={iconOpenMenu} />
                </div>
              </button>
            )}
          </div>
          <MenuImage configMenu={configMenu} />
        </div>
        <div
          className={`bg-[#1B2646] z-10 h-screen w-56 absolute left-11 pl-1 ${
            !isVisibleMenu && " -translate-x-full "
          } duration-500 `}
        >
          <HeaderMenu closeMenu={closeSubMenu} />
          <MenuText configMenu={configMenu} />
        </div>
        <SubMenu
          isVisibleSubMenuFuncionalidades={isVisibleSubMenuFuncionalidades}
          text="Funcionalidades"
          icon={iconModulesBlue}
        >
          <Modulo
            text="Pendência"
            link="/pendencia/consulta"
            onClick={closeSubMenu}
          />
          <Modulo
            text="Usuário"
            link="/usuario/consulta"
            onClick={closeSubMenu}
          />
          <Modulo
            text="Dados Inseridos"
            link="/usuario/dados"
            onClick={closeSubMenu}
          />
        </SubMenu>
      </div>
      <div>
        {isVisibleMenu && (
          <div
            className="fixed z-10 top-14 left-0 w-full h-full bg-[#00000071] flex justify-center items-center"
            onClick={closeSubMenu}
          ></div>
        )}
      </div>
    </div>
  );
};

export default MenuLeft;
