import React, {
  useCallback,
  useEffect,
  useMemo,
  //useRef,
  useState,
} from "react";
import Dropdown from "../../components/Dropdown";
import ImportExcel from "../ImportExcel";
import { utilAPi } from "../../utility/api/utilAPI";
import { dashAPi } from "../../utility/api/dashAPI";
import { processoAPI } from "../../utility/api/processoAPI";
import { downloadAPI } from "../../utility/api/downloadAPI";
import { loginApi } from "../../utility/api/loginApi";
import { selectUserToken } from "../../features/user";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
//import { useSetores } from "../../hooks/useSetores";
import { useDivisao } from "../../hooks/useDivisao";
import { useSetor } from "../../hooks/useSetor";
import Grafico from "./Chart";
import Swal from "sweetalert2";
import Input from "../../components/Input";
import Module from "./Module";

export const data = [
  ["Element", "Sugestões", { role: "style" }],
  ["Enviadas", 0, "#1B2646"], // RGB value
  ["Não Enviadas", 0, "#EBEBEB"], // English color name
];

export const options = [];

const Home = () => {
  const [showAddCategoria, setShowAddCategoria] = useState(false);

  //const [divisao, setDivisao] = useState(false);
  const [resultados, setResultados] = useState(data);
  const [reloadPermissao, setReloadPermissao] = useState(true);
  const token = useSelector(selectUserToken);
  //const setores = useSetores(token);
  //const setorRef = useRef(null);
  //const divisaoRef = useRef(null);

  const form = useForm({
    reValidateMode: "onChange",
    defaultValues: {
      score: 0,
      gravity: null,
      urgency: null,
    },
  });

  const { result: divisaoo } = useDivisao(form);
  const { result: setor } = useSetor(form);

  //const form2 = useForm();
  const { watch, getValues, setValue, clearErrors, trigger } = form;

  const [permissao, setPermissao] = useState(null);

  // const fetchDivisao = useCallback(async () => {
  //   let result = await utilAPi.getDivisoes(token);
  //   setDivisao(result.data);
  // }, [token]);

  const fetchParametros = useCallback(async () => {
    let result = await utilAPi.getParametros(token);

    let date = new Date(result.data.dhFinalizacao);
    date = date
      .toLocaleDateString()
      .replaceAll("/", "-")
      .split("-")
      .reverse()
      .join("-");

    if (date === "1-01-01") date = "";

    form.reset({ ...result.data, dhFinalizacao: date });
  }, [form, token]);

  const fetchResultados = useCallback(async () => {

    let result = await utilAPi.postResultados(
      {
        divisaoId: getValues('divisao') !== '' ? getValues('divisao') : null,
        setorId: getValues('setor') !== '' ? getValues('setor') : null,
      },
      token
    );

    setResultados({
      SugestaoEnviadas: result.data.sugestaoEnviadas,
      AvaliadoresConfirmados: result.data.avaliadoresConfirmados,
    });
  }, [reloadPermissao, token]);

  const fetchPermissoes = useCallback(async () => {
    const result = await loginApi.permissoes(token);
    setPermissao(result.data);
  }, [token]);

  useEffect(() => {
    //fetchDivisao();
    fetchResultados();
  }, [fetchResultados, useDivisao, useSetor]);

  useEffect(() => {
    fetchPermissoes();
    fetchParametros();
  }, [fetchParametros, fetchPermissoes, reloadPermissao]);

  // useEffect(() => {
  //   const subscription = watch((value, { name }) => {
  //     if (name === "qtdeSugestaoMin") {
  //       setValue("qtdeAvaliador", value.qtdeSugestaoMin);
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [setValue, watch]);

  const quantidadeOptions = useMemo(() => {
    return [
      {
        id: "1",
        text: "1",
      },
      {
        id: "2",
        text: "2",
      },
      {
        id: "3",
        text: "3",
      },
      {
        id: "4",
        text: "4",
      },
      {
        id: "5",
        text: "5",
      },
      {
        id: "6",
        text: "6",
      },
      {
        id: "7",
        text: "7",
      },
      {
        id: "8",
        text: "8",
      },
      {
        id: "9",
        text: "9",
      },
    ];
  }, []);

  const postParam = useCallback(
    async (data) => {
      const { qtdeSugestaoMin, qtdeSugestaoMax, qtdeAvaliador, dhFinalizacao } = data;
      if (!qtdeSugestaoMin) return;
      if (!qtdeSugestaoMax) return;
      if (!qtdeAvaliador) return;
      // if (qtdeSugestaoMax < qtdeSugestaoMin) {
      //   trigger("qtdeSugestaoMax");
      //   return;
      // }

      //clearErrors();

      if (!dhFinalizacao) return;

      const result = await dashAPi.postSalvarParametros(data, token);
      console.log("Parametros: ", data);
      if (result.hasError) {
        Swal.fire({
          icon: "info",
          title: result.error?.response?.data?.notifications?.length > 0
            ? result.error?.response?.data?.notifications[0]
            : "",
        });
      }
      // if (
      //   !result.data?.isValid &&
      //   (result.hasError || !result.error?.response?.data?.isValid)
      // ) {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Houve um erro ao salvar parâmetros!",
      //     text:
      // result.error?.response?.data?.notifications?.length > 0
      //   ? result.error?.response?.data?.notifications[0]
      //   : "",
      //   });
      // }
    },
    [token]
  );

  const releaseProcess = useCallback(async () => {
    if (!permissao.liberarProcesso) {
      return;
    }

    Swal.fire({
      title: "Tem certeza que deseja liberar?",
      html:
        "A liberação do processo faz com que seja disparado o link para que as indicações " +
        "inicie, sendo assim <strong>não será permitido NENHUMA ALTERAÇÃO nos usuários " +
        "após essa ação.</strong>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "SIM",
      cancelButtonText: "NÃO",
      reverseButtons: false,
    }).then(async (resultConfirm1) => {
      if (resultConfirm1.isConfirmed) {
        Swal.fire({
          html: "Confirma a <strong>Liberação do processo<strong>?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "SIM",
          cancelButtonText: "NÃO",
          reverseButtons: false,
        }).then(async (resultConfirm2) => {
          if (resultConfirm2.isConfirmed) {
            const result = await processoAPI.inicializar(token);

            if (
              !result.data?.isValid &&
              (result.hasError || !result.error?.response?.data?.isValid)
            ) {
              Swal.fire({
                icon: "info",
                title: "Houve um erro ao liberar processo!",
                text:
                  result.error?.response?.data?.notifications.length > 0
                    ? result.error?.response?.data?.notifications[0]
                    : "",
              });
              return;
            } else {
              setReloadPermissao(!reloadPermissao);
              Swal.fire({
                icon: "success",
                title: "",
                html: "Processo <strong>LIBERADO</strong>",
              });
            }
          }
        });
      }
    });
  }, [permissao?.liberarProcesso, token, reloadPermissao]);

  const cleanBase = useCallback(
    async (data) => {
      if (!permissao.limparBase) {
        return;
      }

      Swal.fire({
        title: "Tem certeza que deseja Limpar Base?",
        html:
          "Limpar os dados da base faz com que <strong>apague TODAS as informações</strong> " +
          "do sistema. Deseja realmente continuar com essa ação?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "SIM",
        cancelButtonText: "NÃO",
        reverseButtons: false,
      }).then(async (resultConfirm1) => {
        if (resultConfirm1.isConfirmed) {
          Swal.fire({
            html: "Confirma a <strong>exclusão de TODAS as informações<strong> do sistema?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "SIM",
            cancelButtonText: "NÃO",
            reverseButtons: false,
          }).then(async (resultConfirm2) => {
            if (resultConfirm2.isConfirmed) {
              const result = await processoAPI.excluir(token);

              if (!result.hasError) {
                setReloadPermissao(!reloadPermissao);
                Swal.fire({
                  icon: "success",
                  title: "",
                  html: "Base de dados atualizada!",
                });
                return;
              } else {
                Swal.fire({
                  icon: "info",
                  title: "Houve um erro ao limpar a base!",
                  text:
                    result.error?.response?.data?.notifications.length > 0
                      ? result.error?.response?.data?.notifications[0]
                      : "",
                });
              }
            }
          });
        }
      });
    },
    [permissao?.limparBase, token, reloadPermissao]
  );

  const startUploadDocument = useCallback(
    async (data) => {
      if (!permissao.documento) {
        return;
      }

      setShowAddCategoria(true);
    },
    [permissao]
  );

  const downloadDocument = useCallback(
    async (data) => {
      if (!permissao.download) {
        return;
      }

      const blob = await downloadAPI.download(token);

      const d = new Date();
      const nameSufix =
        `${String(d.getDate()).padStart(2, "0")}-${String(
          d.getMonth() + 1
        ).padStart(2, "0")}-${d.getFullYear()}-` +
        `${String(d.getHours()).padStart(2, "0")}-${String(
          d.getMinutes()
        ).padStart(2, "0")}-${String(d.getSeconds()).padStart(2, "0")}`;

      const anchor = document.createElement("a");
      const objectUrl = window.URL.createObjectURL(blob);
      document.body.appendChild(anchor);
      anchor.href = objectUrl;
      anchor.download = `Avaliador-${nameSufix}.xlsx`;
      anchor.target = "_blank";
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    },
    [token, permissao]
  );

  const downloadDocumentWithName = useCallback(
    async (data) => {
      if (!permissao.download) {
        return;
      }

      const blob = await downloadAPI.downloadWithName(token);

      const d = new Date();
      const nameSufix =
        `${String(d.getDate()).padStart(2, "0")}-${String(
          d.getMonth() + 1
        ).padStart(2, "0")}-${d.getFullYear()}-` +
        `${String(d.getHours()).padStart(2, "0")}-${String(
          d.getMinutes()
        ).padStart(2, "0")}-${String(d.getSeconds()).padStart(2, "0")}`;

      const anchor = document.createElement("a");
      const objectUrl = window.URL.createObjectURL(blob);
      document.body.appendChild(anchor);
      anchor.href = objectUrl;
      anchor.download = `AvaliadorComNome-${nameSufix}.xlsx`;
      anchor.target = "_blank";
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    },
    [token, permissao]
  );

  const downloadIndicationPending = useCallback(
    async (data) => {
      if (!permissao.relPendentes) {
        return;
      }

      const blob = await downloadAPI.downloadIndicationPending(token);

      const d = new Date();
      const nameSufix =
        `${String(d.getDate()).padStart(2, "0")}-${String(
          d.getMonth() + 1
        ).padStart(2, "0")}-${d.getFullYear()}-` +
        `${String(d.getHours()).padStart(2, "0")}-${String(
          d.getMinutes()
        ).padStart(2, "0")}-${String(d.getSeconds()).padStart(2, "0")}`;

      const anchor = document.createElement("a");
      const objectUrl = window.URL.createObjectURL(blob);
      document.body.appendChild(anchor);
      anchor.href = objectUrl;
      anchor.download = `AvaliadorIndicacoesPendentes-${nameSufix}.xlsx`;
      anchor.target = "_blank";
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    },
    [token, permissao]
  );

  useEffect(() => {
    const subscription = watch(() => {
      postParam({ ...getValues() });
    });
    return () => subscription.unsubscribe();
  }, [getValues, postParam, watch]);

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="w-[80%] l:h-[80%] d-f0:h-[98%] d-f0:pt-[64px] d-f1:h-[98%] d-f2:h-[95%]  d-f3:h-[70%] d-f5:h-[70%] d-f5:pt-0 d-f7:h-[70%] flex">
        <div className="l:w-[30%] d-f5:w-[20%] h-full flex flex-col">
          <div className="w-full py-4 bg-white rounded-md border border-[#ECECEC] flex flex-col items-center px-2">
            <div className="text-lg text-[#1B2646] font-semibold text-center">
              Quantidade de Sugestões
            </div>
            <div className="w-full flex justify-around mt-4 d-f2:mt-2">
              <div className="w-1/2 flex flex-col items-center">
                <div className="text-[#1B2646] text-sm font-semibold">
                  Mínima
                </div>
                <div>
                  <Dropdown
                    defaultText="0"
                    required
                    form={form}
                    name="qtdeSugestaoMin"
                    readOnly={!permissao?.habilitarParametros}
                    options={quantidadeOptions}
                  />
                </div>
              </div>
              <div className="w-1/2 flex flex-col items-center">
                <div className="text-[#1B2646] text-sm font-semibold">
                  Máxima
                </div>
                <div>
                  <Dropdown
                    defaultText="0"
                    required
                    form={form}
                    name="qtdeSugestaoMax"
                    readOnly={!permissao?.habilitarParametros}
                    options={quantidadeOptions}
                    validate={(value) => {
                      if (value < getValues("qtdeSugestaoMin")) {
                        Swal.fire({
                          icon: "error",
                          title: "O parâmetro máximo não pode ser menor que o mínimo",
                        });
                        return false;
                      }
                      return true;
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full py-4 mt-2 bg-white rounded-md border border-[#ECECEC] flex flex-col items-center px-2">
            <div className="text-lg text-[#1B2646] font-semibold text-center">
              Quantidade de Avaliadores
            </div>
            <div className="pt-4">
              <Dropdown
                defaultText="0"
                required
                form={form}
                name="qtdeAvaliador"
                readOnly={!permissao?.habilitarParametros}
                options={quantidadeOptions}
              />
            </div>
          </div>
          <div className="w-full h-full flex-grow d-f0:mt-1 d-f2:mt-2 grid grid-rows-4 items-center gap-2 py-2 bg-white rounded-md border border-[#ECECEC] ">
            <Module onClick={cleanBase} readOnly={permissao?.limparBase}>
              Limpar Base
            </Module>
            <Module
              onClick={releaseProcess}
              readOnly={permissao?.liberarProcesso}
            >
              Liberar Processo
            </Module>
            <Module
              onClick={startUploadDocument}
              readOnly={permissao?.documento}
            >
              Importar Documento
            </Module>
            <Module onClick={downloadDocument} readOnly={permissao?.download}>
              Exportar Documento
            </Module>
            <Module onClick={downloadDocumentWithName} readOnly={permissao?.download}>
              Exportar Documento <br /> Com Nome
            </Module>
            <Module onClick={downloadIndicationPending} readOnly={permissao?.relPendentes}>
              Exportar indicações pendentes
            </Module>
          </div>
        </div>
        <div className="l:w-[70%] d-f5:w-[80%] h-full ml-10  bg-white rounded-md border border-[#ECECEC] flex justify-center">
          <div className="w-[90%] flex flex-col items-center justify-around">
            <div className="mt-10 text-xl text-[#1B2646] font-semibold">
              Indicadores de Progresso
            </div>
            <div className="w-[50%] d-f0:w-[70%] d-f2:w-[60%]">
              {/* Divisão
              <Dropdown
                defaultText="Selecione..."
                required
                name='divisao'
                form={form2}
                options={divisao}
                onChange={fetchResultados}
              /> */}
              <Dropdown
                name="divisao"
                form={form}
                options={divisaoo}
                label="Divisão"
                placeholder="Selecione..."
                onChange={fetchResultados}
              />
            </div>
            <div className="w-[50%] d-f0:w-[70%] d-f2:w-[60%]">
              {/* Setor
              <Dropdown
                defaultText="Selecione..."
                required
                name='setor'
                form={form2}
                options={setores}
                onChange={fetchResultados}
              /> */}
              <Dropdown
                name="setor"
                form={form}
                options={setor}
                label="Setor"
                placeholder="Selecione..."
                onChange={fetchResultados}
              />
            </div>
            {/* <Chart chartType="ColumnChart" width="60%" height="100px" data={resultados} /> */}
            <div className="w-full h-[40%] d-f0:h-[35%] d-f2:h-[35%] d-f2:w-[75%]">
              <Grafico data={resultados} />
            </div>
            <div className="w-[60%] border-b mt-4"></div>
            <div className="w-[50%] d-f0:w-[70%] d-f2:w-[60%] mt-4">
              Data final do processo
              <Input
                form={form}
                name="dhFinalizacao"
                type="date"
                readOnly={!permissao?.habilitarParametros}
                onKeyDown={(e) => e.preventDefault()}
              />
            </div>
          </div>
        </div>
      </div>
      {showAddCategoria && (
        <ImportExcel onClose={() => setShowAddCategoria(false)} />
      )}
    </div>
  );
};

export default Home;
