import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../../utility/api/loginApi";
import { useForm } from 'react-hook-form';
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { userloggedIn, userProfile } from "../../features/user";
import Logo from "../../assets/png/LogoAvaliadores.png";
import OlhoAberto from "../../assets/svg/olho-aberto.svg";
import OlhoFechado from "../../assets/svg/olho-fechado.svg";

const Login = () => {

  const history = useNavigate();
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);

  const { handleSubmit, register } = useForm();

  const onSubmit = useCallback(async ({ email, senha }) => {
    let response = await loginApi.login({ email, senha });
    if (response.hasError) {
      Swal.fire({
        icon: "info",
        title: response.error?.response?.data?.message
      })
      return;
    }
    dispatch(userloggedIn(response.data))
    if (response.data.jwt.perfil === userProfile.Administrador) {
      history("/home");
    }
    else if (response.data.jwt.perfil === userProfile.Avaliado) {
      history("/Avaliado");
    }
    else if (response.data.jwt.perfil === userProfile.Aprovador) {
      history("/AprovacaoSubordinado");
    }
    else if (response.data.jwt.perfil === userProfile.Ambos) {
      history("/AvaliadoAprovador");
    }
  }, [dispatch, history])

  const sendForgotPassword = () => {
    Swal.fire({
      title: 'Insira o seu e-mail para envio da senha.',
      input: 'email',
      validationMessage: 'Endereço de e-mail inválido',
      inputPlaceholder: 'nome.sobrenome@valgroupco.com',
      closeButtonAriaLabel: true,
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Enviar',
      confirmButtonColor: '#187733',
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#9B9B9B'
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await loginApi.recuperarSenha({ email: result.value })
        if (response.hasError) {
          Swal.fire({
            icon: "info",
            title: response.error?.response?.data?.notifications
          })
          return;
        } else {
          Swal.fire({
            title: 'Senha enviada com sucesso!',
          })
        }
      }
    })
  }

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="w-96 h-96 bg-white drop-shadow-lg rounded-xl flex items-center justify-center">
        <form onSubmit={handleSubmit(onSubmit)} className="w-full flex flex-col">
          <div className="w-full flex justify-center mb-4 px-28">
            <img alt="Logo" src={Logo} />
          </div>
          <div className="w-full flex flex-col px-14">
            <div className="text-sm text-[#8A92A6]">E-mail</div>
            <input {...register("email")} className="-full border border-[#686868] rounded-md pl-2 m-f1:py-1 m-f2:py-2 d-f1:py-3 text-[12px] outline-none" placeholder="seuemail@valgroupco.com" required />
          </div>
          <div className="w-full flex flex-col px-14 mt-6">
            <div className="text-sm text-[#8A92A6]">Senha</div>
            <div className="relative w-full">
              <input {...register("senha")} type={isVisible ? "text" : "password"} className="w-full border border-[#686868] rounded-md pl-2 m-f1:py-1 m-f2:py-2 d-f1:py-3 text-[12px] outline-none" placeholder="**************" required />
              <button
                type="button"
                onClick={() => setIsVisible(!isVisible)}
                className="absolute bottom-0 right-0 h-full px-2 w-11 flex items-center justify-center bg-[#1D284A] rounded-r"
              >
                <img
                  alt="Exibir senha"
                  title="Exibit senha"
                  src={isVisible ? OlhoAberto : OlhoFechado}
                />
              </button>
            </div>
            <div className="w-full flex justify-end text-[13px] text-[#1D284A] cursor-pointer hover:underline hover:underline-offset-2" onClick={sendForgotPassword}>recuperar senha</div>
          </div>
          <div className="w-full flex justify-center mt-6">
            <button className="px-10 py-2 text-white bg-[#1D284A] rounded hover:bg-[#222d50] text-[12px] font-light">Acessar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
