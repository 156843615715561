import { NavLink } from "react-router-dom";
import Header from "../../routes/Layout/Header";

const Index = () => {
    return (
        <>
            <Header />
            <div className="w-full h-screen flex flex-col justify-center items-center">
                <div className="text-lg font-semibold text-[#1B2646]">Acessar como:</div>
                <div className="flex">
                    <NavLink className="bg-[#1B2646] text-white px-10 py-4 mt-4 rounded-md hover:bg-[#25335c]" to="/Avaliado">Avaliado</NavLink>
                    <NavLink className="bg-[#1B2646] text-white px-10 py-4 mt-4 rounded-md hover:bg-[#25335c] ml-4" to="/AprovacaoSubordinado">Aprovador</NavLink>
                </div>
            </div>
        </>
    );
}
export default Index;