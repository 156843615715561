import api from "./index";

export const loginApi = {
    login: async (data) => {
        let result = await api.post("/login", data)
        return result
    },
    permissoes: async (token) => {
        return await api.get("/Login/Permissoes", '', token);
    },
    recuperarSenha: async (email) => {
        return await api.get("/Login/RecuperarSenha", email);
    },
}