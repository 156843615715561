import { useEffect } from "react";

export const useClick = (
  element,
  state,
  setter,
  onClickInside,
  onClickOutside
) => {
  useEffect(() => {
    if (!element) return;
    if (!element.current) return;

    const onClick = (event) => {
      if (!element.current.contains(event.target)) {
        if (state) {
          onClickOutside?.();
        }
        setter(false);
      } else if (!state) {
        onClickInside?.();
      }
    };

    if (state) window.addEventListener("click", onClick);

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [element, onClickInside, onClickOutside, setter, state]);
};
