import ContainerModulo from "../../../components/ContainerModulo/ContainerModulo";

const Modulo = ({ text, onClick, link, isNewTab }) => {
  return (
    <ContainerModulo onClick={onClick} link={link} isNewTab={isNewTab}>
      <div className="text-[#1D284A] font-semibold pl-6">{text}</div>
    </ContainerModulo>
  );
};

export default Modulo;
