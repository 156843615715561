import React, { useCallback, useEffect, useId, useState } from "react";

import OlhoAberto from "../../assets/png/olho-aberto.svg";
import OlhoFechado from "../../assets/png/olho-fechado.svg";

const InputMultiline = React.forwardRef(
  (
    {
      label,
      name,
      type = "text",
      placeholder,
      value,
      validate,
      readOnly,
      onChange,
      required,
      className,
      icon,
      button,
      form,
    },
    ref
  ) => {
    const id = useId();
    const [isVisible, setIsVisible] = useState(false);
    const {
      register,
      watch,
      formState: { errors },
    } = form;

    const onTextChange = useCallback(() => {
      let textarea = document.getElementById(id);
      textarea.style.height = `1px`;
      let height = textarea.scrollHeight + 5;
      textarea.style.height = `calc(${height}px)`;
    }, [id]);

    useEffect(() => {
      onTextChange();
    }, [onTextChange]);

    useEffect(() => {
      const subscription = watch((value, { name: watchName, type }) => {
        if (watchName === name || (name === undefined && type === undefined)) {
          onTextChange();
        }
      });
      return () => subscription.unsubscribe();
    }, [name, onTextChange, watch]);

    return (
      <div className="flex flex-col">
        {label && (
          <label htmlFor={id} className="text-[#8A92A6] text-xs pt-1">
            {label}
          </label>
        )}
        <div className="relative w-full">
          <textarea
            id={id}
            ref={ref}
            readOnly={readOnly}
            onChange={() => {
              onTextChange();
              onChange?.();
            }}
            {...register(name, {
              validate: validate,
              value: value,
              onChange: () => {
                onTextChange();
                onChange?.();
              },
              required: required && "Este campo não pode ficar vazio",
            })}
            className={`w-full rounded-md text-xs outline-none resize-none max-h-52 h-[47px]
          ${className}
          ${icon && "pl-10"} 
          ${
            readOnly
              ? "font-semibold"
              : "px-2 border border-[#686868] pl-2 m-f2:py-2 d-f1:py-3"
          }
          ${errors[name] && "!border-[#AF0505]"}`}
            type={isVisible ? "text" : type}
            placeholder={placeholder}
          ></textarea>
          {icon && (
            <img
              className="absolute top-1/2 left-2 -translate-y-1/2"
              alt="Icone de Nome"
              src={icon}
            />
          )}
          {button}
          {type === "password" && (
            <button
              type="button"
              onClick={() => !readOnly && setIsVisible(!isVisible)}
              className="absolute bottom-0 right-0 h-full px-2 w-11 flex items-center justify-center bg-[#1D284A] rounded-r"
            >
              <img
                alt="Exibir senha"
                title="Exibir senha"
                src={isVisible ? OlhoAberto : OlhoFechado}
              />
            </button>
          )}
        </div>
        <div className="flex justify-start">
          {errors[name] && (
            <span className="text-[#AF0505] text-xs my-[3px]">
              {errors[name].message}
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default InputMultiline;
