import { useRef, useState } from "react";
import Swal from "sweetalert2";
import { customJoin } from "../../utility/util";
const useFileContainer = ({multiple, extensions}) => {
  const [arquivos, setArquivos] = useState([]);
  const fileInputRef = useRef(null);
  window.arquivos = arquivos
  const showError = (text) => {
    Swal.fire({
      icon: "error",
      title: "Erro!",
      text: text,
    });
  };

  const validarArquivo = (file) => {
    if(!extensions) 
    return undefined;

    let extension = "."+file.name.split(".").at(-1)
    if(extensions.indexOf(extension) >= 0)
    return undefined;

    return `O arquivo "${file.name}" é inválido. Somente arquivos com as extensões "${customJoin(extensions, '", "','" e "')}" podem ser enviados`;
  };

  const adicionarArquivos = (files) => {
    let temp = [...arquivos];

    for (var file of files) {
      let result = validarArquivo(file);

      if (result) {
        showError(result);
        return;
      }
      temp.push(file);
    }

    setArquivos(temp);
  };
  const adicionarArquivo = (files) => {
    let file = files[0];
    if(!file) return;
    let result = validarArquivo(file);

    if (result) {
      showError(result);
      return;
    }
    if (arquivos.length === 0)
      setArquivos([file]);
    else {
      Swal.fire({
        icon: "question",
        title: "Tem certeza que deseja substituir o arquivo anterior ?",
        confirmButtonText: "Sim",
        denyButtonText: "Não",
        showDenyButton: true,
      }).then((result) => {
        if(result.isConfirmed){
          setArquivos([file]);
        }
      })
    }
  };

  const removerArquivo = (index) => {
    let temp = [...arquivos];
    temp.splice(index, 1);
    setArquivos(temp);
  };

  const openFileSearchModal = () => {
    if (fileInputRef) fileInputRef.current?.click();
  };

  return { fileInputRef, arquivos,  multiple, adicionar:multiple? adicionarArquivos : adicionarArquivo, removerArquivo, openFileSearchModal };
};

export default useFileContainer;
