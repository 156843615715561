const Module = ({ children, readOnly, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`w-full d-f0:h-20 relative d-f2:h-16 d-f2:px-7 l:px-7 d-f5:px-7 d-f7:px-7 d-f5:h-20 flex items-center font-semibold text-[#1B2646] cursor-pointer ${
        readOnly ? "" : " opacity-40"
      }`}
    >
      <div className="relative border flex items-center pl-7 h-full w-full rounded">
        <div className="absolute left-0 w-[7px] h-full bg-[#1d284a] rounded-tl rounded-bl"></div>
        {children}
      </div>
    </div>
  );
};

export default Module;
