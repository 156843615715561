import { NavLink } from "react-router-dom";

const MenuText = ({ configMenu }) => {
  return (
    <div>
      {configMenu.map((menu, index) => (
        <div
          key={index}
          className="mb-16 h-6"
        >
          <div className="text-white cursor-pointer" onClick={menu.actionText}>
            <NavLink to={menu.to && `${menu.to}`}>{menu.title}</NavLink>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MenuText;
