import { columnType } from "../typing";
import Avatar from "../../../assets/png/Avatar-Padrao.png";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const CardColuna = ({ configuration, item }) => {
  let background = "";

  if (configuration.background) {
    background = `!text-white rounded-md ${configuration.background(item)}`;
  }

  let innerContent = (
    <div
      className={`text-left text-sm font-semibold w-full whitespace-nowrap overflow-hidden text-ellipsis ${background} ${configuration.className}`}
    >
      <Tippy content={item[configuration.prop]}>
        <span>{item[configuration.prop]}</span>
      </Tippy>
    </div>
  );

  if (configuration.tipo === columnType.image) {
    innerContent = (
      <div className="w-12 h-12 rounded-[50%] mr-8 border-2 relative overflow-hidden">
        <img
          alt="Sua foto"
          className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full max-w-none ${background} ${configuration.className}`}
          src={item[configuration.prop] ?? Avatar}
        />
      </div>
    );
  }

  let content = <div className="w-full">{innerContent}</div>;

  if (configuration.link) {
    content = (
      <button type="button" onClick={() => configuration.link(item)}>
        {innerContent}
      </button>
    );
  }

  return (
    <div
      className="flex pr-4"
      style={{
        width: configuration.width,
      }}
    >
      <div className="flex flex-col w-full">
        {configuration.label && (
          <div className="text-sm text-[#8A92A6]">
            {configuration.label}
          </div>
        )}
        {content}
      </div>
    </div>
  );
};

export default CardColuna;
