import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import Dropdown from "./Dropdown";

import LogoutIcon from "../../../../assets/svg/Logout.svg";
import Avatar from "../../../../assets/png/Avatar-Padrao.png";
import { selectUser, userLoggedOut } from "../../../../features/user";

const UserInfo = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const user = useSelector(selectUser);

  const onLogout = useCallback(() => {
    dispatch(userLoggedOut());
    history("/home");
  }, [dispatch, history]);

  let userImagem = Avatar;

  return (
    <div className="flex">
      <Dropdown userImagem={userImagem} user={user} />
      <div className="my-2 border-r-[1px] border-[#898282] mx-2 m-f1:mx-0 t-f1:mx-2"></div>
      <div className="flex w-10 mx-2 items-center m-f1:mx-1 justify-center">
        <button
          className="w-4 h-4 flex items-center justify-center mr-1"
          onClick={onLogout}
        >
          <img alt="Sair" src={LogoutIcon} />
        </button>
      </div>
    </div>
  );
};

export default UserInfo;
