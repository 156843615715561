const SubMenu = ({ isVisibleSubMenuFuncionalidades, text, children, icon }) => {
  return (
    <div
      className={`absolute bg-[#f1ecec] z-0 h-screen left-[0px] w-56 -translate-x-full pt-4 ${
        isVisibleSubMenuFuncionalidades
          ? "left-[30.5rem] translate-x-full"
          : "left-0"
      } duration-500`}
    >
      <div className="w-full grid grid-rows-6 gap-7">
        <div className="w-full flex justify-center">
          <div className="w-[90%] h-full flex items-end border-b border-[#1D284A]">
            <div className="py-2 font-semibold text-[#1D284A] flex">
              <div className="w-6 mr-3">
                <img alt="Módulos" src={icon} />
              </div>
              <span>{text}</span>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};
export default SubMenu;
