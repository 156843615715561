import { useForm } from "react-hook-form";
import { useState, useEffect, useCallback, useMemo } from "react";
import useQuery from "../../utility/useQuery";
import Swal from "sweetalert2";
import { useNavigate, useSearchParams } from "react-router-dom";
import { deepCopy } from "../../utility/util";

const useConsulta = (token, config, cardOptions) => {
  let query = useQuery();
  let page = parseInt(query.get("page") ?? 0);

  const form = useForm();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const itemsQuantity = 4;

  const [isLoading, setIsLoading] = useState(true);
  const [filtersHistory, setFiltersHistory] = useState({});
  const [items, setItems] = useState({
    recordsFiltered: 4,
    recordsTotal: 4,
    take: itemsQuantity,
    records: [],
  });

  const fixPaginationWhenFilterActive = (filterActual) => {

    const isFilterChanging = JSON.stringify(filterActual) !== JSON.stringify(filtersHistory);
    
    if (isFilterChanging) {
      setFiltersHistory(filterActual);

      if (searchParams.has('page')) {
        searchParams.delete('page');
        setSearchParams(searchParams);
      }
      
    }
  }

  const onSearch = useCallback(
    async (onFinished) => {
      if (!token) return;

      let filtros = {
        ...form.getValues(),
        page: page,
        take: itemsQuantity,
      };

      fixPaginationWhenFilterActive(form.getValues());

      let response = null;

      try {
        response = await config.onSearch.fetchFunction(filtros, token);
      }
      catch(err) {
        console.log('response', response);
        console.log('error', err);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 250);

      if (response.hasError) {
        if (!response.isUnathorized)
          Swal.fire({
            title: "Erro!",
            text: "Algo deu errado",
            icon: "error",
          });
      } else {
        setItems({ ...response.data, take: 4 });

        let quantidadeMaximaPaginas =
          response.data.recordsTotal / itemsQuantity;

        if (quantidadeMaximaPaginas < page && response.data.recordsTotal > 0) {
          navigate("?page=0");
        }
        onFinished?.();
      }
    },
    [token, form, page, config.onSearch, navigate]
  );

  useEffect(() => {
    onSearch();

    const subscription = form.watch(() => onSearch());
    return () => subscription.unsubscribe();
  }, [onSearch, form.watch, form]);

  const cardOptionsWrapper = useMemo(() => {
    let temp = deepCopy(cardOptions);

    let keys = Object.keys(temp);

    for (let key of keys) {
      if (!Boolean(temp[key].fetchFunction)) continue;

      temp[key].action = async (item, onSearch) => {
        Swal.fire({
          title: temp[key].questionText,
          showDenyButton: true,
          confirmButtonText: "Sim",
          denyButtonText: `Não`,
        }).then(async (swalResult) => {
          if (swalResult.isConfirmed) {
            var result = await temp[key].fetchFunction(item.id, token);

            if (result.hasError) {
              Swal.fire({
                title: "Erro!",
                text: "Algo deu errado",
                icon: "error",
              });
            } else {
              (temp[key].successFunction &&
                temp[key].successFunction(item, onSearch)) ||
                onSearch(() => {
                  Swal.fire({
                    title: "Sucesso!",
                    text: temp[key].successText,
                    icon: "success",
                  });
                });
            }
          }
        });
      };
    }

    return temp;
  }, [cardOptions, token]);

  return {
    isLoading,
    form,
    items,
    pagina: page,
    onSearch,
    cardOptions: cardOptionsWrapper,
  };
};

export default useConsulta;
