import Header from "./Header";
import MenuLeft from "./MenuLeft";

import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserToken } from "../../features/user";
const Layout = () => {
    window.token = useSelector(selectUserToken);
    return (
        <>
            <Header />
            <MenuLeft />
            <Outlet />
        </>
    );
}
export default Layout;