import React from "react";
import CardOptions from "./CardOptions";
import CardColuna from "./CardColuna";

export const cardBackground = {
  red: "bg-[#AF0011]",
  green: "bg-[#187733]",
};

const Card = ({ item, colunas, onSearch, cardOptions, allowSelectLine=false, onCardSelected, isSelected, totalItems}) => {

  const clickCard = () => {
    if(!allowSelectLine) {
      return 
    }

    onCardSelected(item, !isSelected);
  }

  let gridConfig = "t-f1:grid-cols-[minmax(calc(100%_-_180px),_1fr)_minmax(180px,_180px)]";

  if (cardOptions.config.hideStatus)
    gridConfig = "t-f1:grid-cols-[minmax(calc(100%_-_50px),_1fr)_minmax(50px,_300px)]";

  return (
    <div className={`w-full min-h-[80px] py-2 px-[2%] grid grid-cols-[1fr] ${gridConfig} items-center justify-center mt-2 border ${(isSelected ? 'bg-[#CBEFCD] border-[#0000003d]' : 'bg-white')} rounded-lg`} onClick={clickCard}>
      <div className={`t-f1:flex ${cardOptions.config.hasWrap && "flex-wrap"}`}>
        {colunas.map((coluna, index) => (
          <CardColuna key={index} configuration={coluna} item={item} />
        ))}
      </div>
      <div className="flex justify-between content-center">
        {!cardOptions.config.hideStatus && (
          <div className="flex">
            {item.status && (
              <div className="flex flex-col">
                <div className="text-[13px] text-[#8A92A6]">Status</div>
                <div>
                  <button
                    className={`${
                      item.status === "Aprovado" || item.status === "Enviado" || item.status === "Ativo"
                        ? "bg-[#187733]"
                        : "bg-[#AF0011] "
                    } text-[10px] py-1 px-1 font-semibold text-white rounded-md w-[60px]`}
                  >
                    {item.status}
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
        <div className="flex justify-center">
          {cardOptions && (
            <CardOptions
              item={item}
              cardOptions={cardOptions}
              onSearch={onSearch}
              totalItems={totalItems}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
