import React, { useRef, useState } from "react";
import { useClick } from "../../../hooks/useClick";

const CardOptions = ({ item, onSearch, cardOptions, totalItems }) => {
  const [isVisible, setIsVisible] = useState(false);
  const container = useRef(null);

  useClick(container, isVisible, setIsVisible);

  let optionKeys = Object.keys(cardOptions).filter((item) => item !== "config");

  let hasAlert = false;
  if (cardOptions.config.hasAlert) hasAlert = cardOptions.config.hasAlert(item);
  if(cardOptions.config && cardOptions.config.hasOwnProperty('isVisible') && !cardOptions.config.isVisible) {
    return <></>
  }
  
  return (
    <>
      <div className="flex justify-end relative">
        {hasAlert && (
          <div className="absolute h-2 w-2 bg-red-600 rounded-full top-1 right-1"></div>
        )}
        <div
          ref={container}
          onClick={() => setIsVisible(!isVisible)}
          className="acoes w-[40px] h-[40px] bg-[#F4F4F4] rounded-[50%] flex flex-col items-center cursor-pointer"
        >
          <div
            className={`w-auto top-[100%] h-auto mt-2 absolute rounded-lg bg-white shadow-lg z-10 ring-1 ring-black ring-opacity-5 overflow-y-auto right-0 ${
              isVisible ? "" : "hidden"
            }`}
          >
            <ul className="text-black cursor-pointer flex flex-col items-end w-36">
              {optionKeys.map((optionKey, index) => {
                let isVisible = cardOptions[optionKey].isVisible
                  ? cardOptions[optionKey].isVisible?.(item)
                  : true;

                let content = "";

                let hasAlert = false;
                if (cardOptions[optionKey].hasAlert) hasAlert = cardOptions[optionKey].hasAlert(item);

                if (isVisible)
                  content = (
                    <li
                      className="w-full flex justify-end text-[#676767] px-4 py-2 text-[13px] border-b hover:bg-slate-100 relative h-9"
                      onClick={() => {
                        cardOptions[optionKey].action?.(item, onSearch, totalItems);
                        setIsVisible(false);
                      }}
                    >
                      {hasAlert && <span className="absolute h-2 w-2 bg-red-600 rounded-full top-1/2 -translate-y-1/2 left-2"></span>}
                      {cardOptions[optionKey].label}
                    </li>
                  );

                return <React.Fragment key={index}>{content}</React.Fragment>;
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardOptions;
