import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import Body from "../../components/Modal/body";
import Modal from "../../components/Modal/Modal";
import Input from "../../components/Input";

import Button, { buttonColor } from "../../components/Button";
import CancelarButton from "../../components/CancelarButton";
import { pedenciaApi } from "../../utility/api/pedenciaAPI";
import Swal from "sweetalert2";


const ImportExcel = ({ onFinished, onClose, item, token }) => {
  const form = useForm();
  window.form = form;

  form.reset({ ...item });
  const { getValues } = form;
  
  const onClickResolve = async () => {
    var itemForm = getValues();

    const data = {
      id: itemForm.id,
      unidade: itemForm.unidade,
      cpf: itemForm.cpf,
      nome: itemForm.nome,
      email: itemForm.email,
      cargo: itemForm.cargo,
      nivel: itemForm.nivel,
      centroCusto: itemForm.centroCusto,
      superiorImediato: itemForm.superiorImediato,
      emailSuperior: itemForm.emailSuperior
    };

    const isCpfValido = (cpf) => {	
      cpf = cpf.replace(/[^\d]+/g,'');	
      if(cpf == '') return false;	
      // Elimina CPFs invalidos conhecidos	
      if (cpf.length != 11 || 
        cpf == "00000000000" || 
        cpf == "11111111111" || 
        cpf == "22222222222" || 
        cpf == "33333333333" || 
        cpf == "44444444444" || 
        cpf == "55555555555" || 
        cpf == "66666666666" || 
        cpf == "77777777777" || 
        cpf == "88888888888" || 
        cpf == "99999999999")
          return false;		
      // Valida 1o digito	
      let add = 0;	
      let rev = 0;
      for (let i=0; i < 9; i ++)		
        add += parseInt(cpf.charAt(i)) * (10 - i);	
        rev = 11 - (add % 11);	
        if (rev == 10 || rev == 11)		
          rev = 0;	
        if (rev != parseInt(cpf.charAt(9)))		
          return false;		
      // Valida 2o digito	
      add = 0;	
      for (let i = 0; i < 10; i ++)		
        add += parseInt(cpf.charAt(i)) * (11 - i);	
      rev = 11 - (add % 11);	
      if (rev == 10 || rev == 11)	
        rev = 0;	
      if (rev != parseInt(cpf.charAt(10)))
        return false;		
      return true;   
    }

    const validateEmail = (email) => {
      return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    if (!isCpfValido(data.cpf)) {
      Swal.fire({
        icon: "error",
        title: "CPF inválido.",
      });

      return;
    }

    if (!validateEmail(data.email)) {
      Swal.fire({
        icon: "error",
        title: "E-mail inválido.",
      });

      return;
    }

    if (data.nivel != "Diretoria") {
        if (!validateEmail(data.emailSuperior)) {
          Swal.fire({
            icon: "error",
            title: "E-mail do superior inválido.",
          });

          return;
        }
    }

    const result = await pedenciaApi.resolver(data, token);

    if (result.hasError) {
      Swal.fire({
        icon: "error",
        title: "Houve um erro ao tentar resolver pendência!",
        text: result.error?.response?.data?.notifications.length > 0 ? result.error?.response?.data?.notifications[0] : ''
      });
      return;
    } else {
      Swal.fire({
        icon: "success",
        title: "Pendência resolvida com sucesso!",
      });
    }

    onClose();
  }

  return (
    <Modal title="Resolver Pendência" onClose={onClose} width="w-[50%]">
      <Body style={{ overflow: "auto" }}>
        <div className="grid grid-cols-3 gap-4" >
          <div className="col-span-3">
            <Input
              name="nome"
              label="Nome"
              form={form}
              className="max-h-40 border-red-500"
              readOnly={form.getValues("nome") == null || form.getValues("nome") == '' ? false : true }
            />
            {form.getValues("nome") == null || form.getValues("nome") == '' ? <div className="text-red-500 text-[13px]">campo obrigatório</div> : true}
          </div>
          <div className="col-span-3">
            <Input
              name="email"
              label="E-mail"
              form={form}
              className="max-h-40 border-red-500"
              readOnly={form.getValues("email") == null || form.getValues("email") == '' ? false : true }
            />
            {form.getValues("email") == null || form.getValues("email") == '' ? <div className="text-red-500 text-[13px]">campo obrigatório</div> : true}
          </div>
          <div className="col-span-3">
            <Input
              name="cpf"
              label="CPF"
              form={form}
              className="max-h-40 border-red-500"
              readOnly={form.getValues("cpf") == null || form.getValues("cpf") == '' ? false : true }
            />
            {form.getValues("cpf") == null || form.getValues("cpf") == '' ? <div className="text-red-500 text-[13px]">campo obrigatório</div> : true}
          </div>
          { form.getValues("unidade") == null || form.getValues("unidade") == '' &&
          <div className="col-span-3">
            <Input
              name="unidade"
              label="Unidade"
              form={form}
              className="max-h-40 border-red-500"
              readOnly={form.getValues("unidade") == null || form.getValues("unidade") == '' ? false : true }
            />
            {form.getValues("unidade") == null || form.getValues("unidade") == '' ? <div className="text-red-500 text-[13px]">campo obrigatório</div> : true}
          </div>
          }
          { form.getValues("nivel") == null || form.getValues("nivel") == '' &&
          <div className="col-span-3">
            <Input
              name="nivel"
              label="Nível"
              form={form}
              className="max-h-40 border-red-500"
              readOnly={form.getValues("nivel") == null || form.getValues("nivel") == '' ? false : true }
            />
            {form.getValues("nivel") == null || form.getValues("nivel") == '' ? <div className="text-red-500 text-[13px]">campo obrigatório</div> : true}
          </div>
          }
          { form.getValues("cargo") == null || form.getValues("cargo") == '' &&
          <div className="col-span-3">
            <Input
              name="cargo"
              label="Cargo"
              form={form}
              className="max-h-40 border-red-500"
              readOnly={form.getValues("cargo") == null || form.getValues("cargo") == '' ? false : true }
            />
            {form.getValues("cargo") == null || form.getValues("cargo") == '' ? <div className="text-red-500 text-[13px]">campo obrigatório</div> : true}
          </div>
          }
          { form.getValues("centroCusto") == null || form.getValues("centroCusto") == '' &&
          <div className="col-span-3">
            <Input
              name="centroCusto"
              label="Centro de Custo"
              form={form}
              className="max-h-40 border-red-500"
              readOnly={form.getValues("centroCusto") == null || form.getValues("centroCusto") == '' ? false : true }
            />
            {form.getValues("centroCusto") == null || form.getValues("centroCusto") == '' ? <div className="text-red-500 text-[13px]">campo obrigatório</div> : true}
          </div>
          }
          { form.getValues("nivel") != "Diretoria" &&
          <div className="col-span-3">
            <Input
              name="superiorImediato"
              label="Superior Imediato"
              form={form}
              className="max-h-40 border-red-500"
              readOnly={form.getValues("superiorImediato") == null || form.getValues("superiorImediato") == '' ? false : true }
            />
            {form.getValues("superiorImediato") == null || form.getValues("superiorImediato") == '' ? <div className="text-red-500 text-[13px]">campo obrigatório</div> : true}
          </div>
          }
          { form.getValues("nivel") != "Diretoria" && (form.getValues("emailSuperior") == null || form.getValues("emailSuperior") == '') &&
          <div className="col-span-3">
            <Input
              name="emailSuperior"
              label="E-mail do Superior"
              form={form}
              className="max-h-40 border-red-500"
              readOnly={form.getValues("emailSuperior") == null || form.getValues("emailSuperior") == '' ? false : true }
            />
            {form.getValues("nivel") != "Diretoria" && (form.getValues("emailSuperior") == null || form.getValues("emailSuperior") == '') ? <div className="text-red-500 text-[13px]">campo obrigatório</div> : true}
          </div>
          }
          {/* <div className="col-span-3">
            <Input
              name="validacoes"
              label="Validações"
              form={form}
              className="max-h-40"
              readOnly={form.getValues("validacoes") == null || form.getValues("validacoes") === '' ? false : true }
            />
            {form.getValues("validacoes") == null || form.getValues("validacoes") == '' ? <div className="text-red-500 text-[13px]">campo obrigatório</div> : true}
          </div> */}
          <div className="relative top-5 flex justify-between col-span-3">
            <CancelarButton form={form} onReturn={() => onClose()} />
            <Button color={buttonColor.darkGreen} onClick={() => onClickResolve()}>Salvar</Button>
          </div>
        </div>
        <div className="col-span-3">
          &nbsp;
        </div>
      </Body>
    </Modal>
  );
};
export default ImportExcel;