import { useCallback, useEffect, useState } from "react";
import Dropdown from "../../Dropdown";
import { filterType } from "../typing";
import Input from "../../../components/Input";
const Filtro = ({ filtro, form }) => {
  const [options, setOptions] = useState([]);

  const fetch = useCallback(async () => {
    let result = await filtro.fetch();

    if (result.hasError) return;

    setOptions(result.data);
  }, [filtro]);

  useEffect(() => {
    if (filtro.fetch) fetch();
  }, [fetch, filtro.fetch]);

  if (filtro.element) {

    return (
      <div className={`flex m-f1:w-full t-f1:w-[30%] m-f1:mr-0 t-f1:mr-[5%] t-f1:ml-[4%] t-f1:pt-0 m-f1:pt-[3%]`}>
        <div className="w-full flex flex-col h-16">{filtro.element(form)}</div>
      </div>
    );
  }

  if (!filtro.type || filtro.type === filterType.input) {
    return (
      <div className="flex m-f1:w-full t-f1:w-[30%] m-f1:mr-0 t-f1:mr-[5%] t-f1:ml-[4%] t-f1:pt-0 m-f1:pt-[3%]">
        <div className="w-full flex flex-col h-16">
          <div className="text-sm text-[#8A92A6]">{filtro.label}</div>
          <div className="text-sm">
            <Input
              className="w-full pl-3 border border-[#686868] rounded-md py-3 text-black"
              placeholder={filtro.placeholder}
              form={form}
              name={filtro.name}
            />
          </div>
        </div>
      </div>
    );
  }

  if (filtro.type === filterType.select) {
    return (
      <div className="w-[30%] mr-[5%] flex">
        <div className="w-full flex flex-col h-16">
          <div className="text-sm text-[#8A92A6]">{filtro.label}</div>
          <Dropdown
            defaultText={filtro.placeholder}
            name={filtro.name}
            form={form}
            options={options}
            fetch={filtro.fetch}
          />
        </div>
      </div>
    );
  }
};

export default Filtro;
