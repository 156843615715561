import { configureStore } from "@reduxjs/toolkit";
import usersReducer from "./user";
import { saveState, loadState } from "./localStorage";
import { throttle } from "lodash";

const store = configureStore({
  reducer: {
    user: usersReducer,
  },
  preloadedState: loadState(),
});

store.subscribe(throttle(() => saveState(store.getState()), 1000));

export default store;
