import React from "react";
import { Provider } from "react-redux";
import store from "./features/store";
import AppRoutes from "./AppRoutes";

import "./App.css";

const App = () => {
  return (
    <Provider store={store}>
      <div id="background-geral" className="w-screen h-screen">
        <AppRoutes />
      </div>
    </Provider>
  );
};
export default App;
