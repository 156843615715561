import { useState, useCallback, useEffect } from "react";
import { utilAPi } from '../utility/api/utilAPI';

export const useSetores = (token) => {
    const [setor, setSetor] = useState(false);

    const fetchSetor = useCallback(async () => {
        let result = await utilAPi.getSetor(token);
        setSetor(result.data);
    }, [token]);

    useEffect(()=> {
        fetchSetor();
    }, [fetchSetor])
    return setor;
}