import React from "react";
import Header from "./header";

const Modal = ({title, children, onClose, width="w-[50%]"}) => {

  return (
    <div>
      <div className="fixed z-20 top-0 left-0 w-full h-full bg-[#69626271]" onClick={onClose}/>
      <div className={`fixed z-30 top-0 right-0 ${width} h-full`}>
         <div className="flex flex-col bg-white h-full elem translate-x-full">
          <Header title={title} onClose={onClose}/>
          {children}
        </div>
      </div>
    </div>)

}

export default Modal; 