import { useMemo, useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectUserToken } from "../../features/user";
import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import { usuarioApi } from "../../utility/api/usuarioAPI";

import { utilAPi } from "../../utility/api/utilAPI";
import {
  useParams,
  useNavigate,
} from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../routes/Layout/Header";
import useQuery from "../../utility/useQuery";

export const AvaliadorSubstituido = ({ children }) => {
  return (
    <div className="flex items-center mt-2">
      <div className="text-[15px] text-black mr-2">Avaliador substituído:</div>
      <div className="bg-[#ECECEC] px-4 py-4 text-[15px] rounded-md">
        {children}
      </div>
    </div>
  );
};

const AprovacaoSubordinado = () => {
  const token = useSelector(selectUserToken);
  const [itemChange, setItemChange] = useState(null);
  const [avaliadorId, setAvaliadorId] = useState(0);
  const [parametros, setParametros] = useState(null);
  const [avaliadoresAtuais, setAvaliadoresAtuais] = useState([]);
  const [itemsConsulta, setItemsConsulta] = useState([]);

  const queryString = useQuery();
  const page = parseInt(queryString.get("page") ?? 0);

  const filtros = [
    {
      label: "Nome",
      placeholder: "Pesquise por qualquer coluna",
      name: "queryNome",
    },
  ];

  const columnSelect = [
    {
      label: "Subordinado",
      prop: "subordinado",
      width: "16%",
    },
    {
      label: "Sugestão do Avaliador",
      prop: "sugestaoAvaliador",
      width: "24%",
    },
    {
      label: "Setor",
      prop: "setor",
      width: "8%",
    },
    {
      label: "Unidade",
      prop: "unidade",
      width: "8%",
    },
    {
      label: "Qtd de Sugestão",
      prop: "qtdeSugestao",
      width: "16%",
    },
    {
      label: "Qtd de Avalição Confirmada",
      prop: "qtdeAvaliacaoConfirmada",
      width: "24%",
    },
  ];

  const columnAvaliado = [
    {
      label: "Nome",
      prop: "nome",
      width: "25%",
    },
    {
      label: "Setor",
      prop: "setor",
      width: "25%",
    },
    {
      label: "Divisão",
      prop: "divisao",
      width: "25%",
    },
    {
      label: "Cargo",
      prop: "cargo",
      width: "25%",
    },
  ];
  const fetchParametros = useCallback(async () => {
    let result = await utilAPi.getParametros(token);
    setParametros(result.data);
  }, [token]);
  
  useEffect(() => {
    fetchParametros();
  }, []);

  const navigate = useNavigate();

  const params = useParams();

  let eventsSubordinado = useMemo(() => {
    const data = {
      page,
      take: 4,
      subordinadoId: params.id,
    };
    return {
      onSearch: {
        fetchFunction: async () => {
          const result = await usuarioApi.aprovacaoSubordinados(data, token);

          const dataFull = {
            page: 0,
            take: 10000,
            subordinadoId: params.id,
          };

          const resultFull = await usuarioApi.aprovacaoSubordinados(dataFull, token);
          
          const avaliadoresSugeridos = [];
          for (let index = 0; index < resultFull.data?.records.length; index++) {
            const element = resultFull.data?.records[index];
            avaliadoresSugeridos.push(element.id);
          }

          if (resultFull.data?.records.length > 0) {
            setAvaliadoresAtuais(avaliadoresSugeridos);
          }

          return result;
        }
      },
    };
  }, [page, params.id, token]);

  let eventsAvaliado = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: (filtros) => {
          if (avaliadorId !== 0) {


            return usuarioApi.substituirAvaliador(
              {
                queryNome: filtros.queryNome,
                page,
                take: 4,
                avaliadorId: avaliadorId,
                usuarioId: params.id,
                avaliadoresEscolhidoIds: avaliadoresAtuais
              },
              token
            );
          }
          else {
            return { data: { recordsTotal: 0, records: [] } };
          }
        },
      },
    };
  }, [avaliadorId, page, params.id, token]);

  const ChangeAvaliador = (item) => {
    setAvaliadorId(item.id);
    setItemChange(item);
    setConfirmationMode(false);
  };

  const excluirAvaliado = (item, onSearch, totalItems) => {
    if(totalItems > parametros.qtdeAvaliador) {
      Swal.fire({
        title: 'Tem certeza que deseja excluir?',
        showDenyButton: true,
        confirmButtonText: 'Salvar',
        denyButtonText: `Cancelar`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          usuarioApi.excluirAvaliador({
            avaliadoId: params.id,
            avaliadorId: item.id
          },token)
          onSearch();
          setItemChange(null);
          setAvaliadorId(0);
          Swal.fire({
            icon: "success",
            title: "",
            html: "Exclusão efetuada com sucesso!",
            showCloseButton: true,
            allowOutsideClick: false
          }).then((result)=> {
            if(result.isConfirmed) {
              setTimeout(function(){
              window.location.reload(
                navigate("?page=0")
              );
              onSearch()
            }, 1000);
            }
          })
        
        }
      })
      
    } else {
      Swal.fire({
        icon: "info",
        title: "Exclusão negada!",
        text: `Quantidade mínima necessária: ${parametros.qtdeAvaliador}`,
      });
    }
  }
    
  const cardOptionsAprovador = useMemo(() => {
    return {
      config: {
        hasAlert: (item) => item.hasAlert,
      },
      editar: {
        label: "Alterar",
        action: (item) => ChangeAvaliador(item),
        hasAlert: (item) => item.hasAlert,
      },
      excluir: {
        label: "Excluir",
        action: (item, onSearch, totalItems) => excluirAvaliado(item, onSearch, totalItems)
      },
    };
  }, [parametros]);

  const cardOptionsAvaliado = useMemo(() => {
    return {
      config: {
        hideStatus: true,
        isVisible: false,
      },
    };
  }, []);

  const consultaSubordinado = useConsulta(
    token,
    eventsSubordinado,
    cardOptionsAprovador
  );

  const consultaAvaliadores = useConsulta(
    token,
    eventsAvaliado,
    cardOptionsAvaliado
  );

  const [itemsSelected, setItemsSelected] = useState([]);
  const [itemsSelectedAvaliadores, setItemsSelectedAvaliadores] = useState([]);
  const [isConfirmationMode, setConfirmationMode] = useState(false);

  const onCardSelectedAprovador = (item, isSelected) => {
    if (isSelected) {
      setItemsSelected([item]);
    } else {
      const myArray = itemsSelected.filter(function (obj) {
        return obj.id !== item.id;
      });
      setItemsSelected(myArray);
    }
  };

  const onCardSelectedAvaliador = (item, isSelected) => {
    if (isSelected) {
      setItemsSelectedAvaliadores([item]);
    } else {
      setItemsSelectedAvaliadores([]);
    }
  };

  const Cancel = () => {
    setItemsSelected([]);
    navigate("/AprovacaoSubordinado");
  };

  const Confirm = () => {
    if (!params.id) {
      Swal.fire({
        icon: "error",
        title: "Seleção inválida!",
        text: "Nenhum aprovador selecionado para seguir com a aprovação.",
      });
      return;
    }

    
    const subordinados = [params.id];
    const { items } = consultaSubordinado;
    const nomeSubordinado =
      items.records.length > 0 ? items.records[0].subordinado : "";

    Swal.fire({
      title: "Tem certeza que deseja Aprovar?",
      html:
        "Aprovar os avaliadores do subordinado é uma ação <strong>PERMANENTE</strong> e " +
        "<strong>NÃO PODE SER DESFEITA</strong>.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "APROVAR",
      cancelButtonText: "CANCELAR",
      reverseButtons: false,
    }).then(async (resultConfirm1) => {
      if (resultConfirm1.isConfirmed) {
        Swal.fire({
          html: `Deseja <strong>REALMENTE</strong> enviar a aprovação dos avaliadores do ${nomeSubordinado}?`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "APROVAR",
          cancelButtonText: "CANCELAR",
          reverseButtons: false,
        }).then(async (resultConfirm2) => {
          if (resultConfirm2.isConfirmed) {
            const result = await usuarioApi.aprovarSubordinado(
              subordinados,
              token
            );

            if (!result.hasError) {
              Swal.fire({
                icon: "success",
                title: "",
                html: `Aprovação do ${nomeSubordinado} enviado com sucesso!`,
              });
              navigate("/AprovacaoSubordinado");
            } else {
              Swal.fire({
                icon: "info",
                title: "Houve um erro ao enviar a aprovação dos avaliadores.",
                text:
                  result.error?.response?.data?.notifications.length > 0
                    ? result.error?.response?.data?.notifications[0]
                    : "",
              });
            }
          }
        });
      }
    });
  };

  const CancelChange = () => {
    setItemsSelectedAvaliadores([]);
    setAvaliadorId(0);
    navigate(`/AprovacaoSubordinadoSelecionado/${params.id}`);
  };

  const ConfirmChange = async () => {
    if (!itemsSelectedAvaliadores || itemsSelectedAvaliadores.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Seleção inválida!",
        text: "Selecione pelo menos um avaliador para continuar com a alteração.",
      });
      return;
    }

    const avaliadorNovo = itemsSelectedAvaliadores[0];

    const result = await usuarioApi.substituirAvaliadorConfirm(
      {
        avaliadoId: params.id,
        avaliadorAntigoId: avaliadorId,
        avaliadorNovoId: avaliadorNovo.avaliadorId,
      },
      token
    );

    if (result.hasError) {
      Swal.fire({
        icon: "error",
        title: "Houve um erro na alteração do avaliador!",
        text:
          result.error?.response?.data?.notifications.length > 0
            ? result.error?.response?.data?.notifications[0]
            : "",
      });
      return;
    } else {
      const { onSearch } = consultaSubordinado;

      Swal.fire({
        icon: "success",
        title: "Alteração do avaliador realizado com sucesso!",
      }).then((result) => {
        //navigate(`/AprovacaoSubordinadoSelecionado/${params.id}?page=0`);
        window.location.reload(
          navigate('?page=0')
        )
        //onSearch();
        //setItemsSelectedAvaliadores([]);
        //setAvaliadorId(0);
      });
    }
  };
    


  return (
    <>
      <Header />
      <div className="flex flex-col items-center">
        {avaliadorId === 0 && (
          <div className="w-full flex flex-col items-center">
            <Consulta
              title="Aprovação dos Subordinados Selecionados"
              colunas={columnSelect}
              consulta={consultaSubordinado}
              allowSelectLine={true}
              onCardSelected={onCardSelectedAprovador}
              checkItemSelection={(item) =>
                itemsSelected.find((i) => i.id === item.id) != null
              }
            >
              <div className="w-full mt-2 flex mx-auto ml-0">
                <div className="flex">
                  <button
                    className="py-4 px-14 bg-[#187733] text-white rounded-md mr-6"
                    onClick={Confirm}
                  >
                    Aprovar
                  </button>
                  <button
                    className="py-4 px-14 bg-[#9B9B9B] text-white rounded-md"
                    onClick={Cancel}
                  >
                    Cancelar
                  </button>
                </div>
                <div className="ml-4 flex justify-center items-center">
                  <div className="text-[#1B2646] font-semibold">Quantidade de avaliadores: {parametros?.qtdeAvaliador}</div> 
                </div>
              </div>
            </Consulta>
          </div>
        )}
        {avaliadorId !== 0 && (
          <div className="w-full">
            {/* <div className="text-[14px] font-semibold mr-4 text-[#1B2646]" style={{ marginTop: 80 }}>
              <div style={{ display: 'inline', marginRight: 5 }}>Avaliador substituído</div>
              <div style={{ display: 'inline', backgroundColor: 'lightgray', borderRadius: 5, padding: 10 }}>{itemChange?.sugestaoAvaliador}</div>
          </div> */}
            <Consulta
              key={avaliadorId}
              title={
                <div>
                  Sugestão de Avaliadores
                  {
                    <AvaliadorSubstituido>
                      {itemChange?.sugestaoAvaliador}
                    </AvaliadorSubstituido>
                  }
                </div>
              }
              colunas={columnAvaliado}
              filtros={filtros}
              consulta={consultaAvaliadores}
              allowSelectLine={true}
              onCardSelected={onCardSelectedAvaliador}
              checkItemSelection={(item) =>
                itemsSelectedAvaliadores.find(
                  (i) => i.avaliadorId === item.avaliadorId
                ) != null
              }
            >
              <div className="w-[96%] mt-2 flex mx-auto ml-0">
                <button
                  className="py-4 px-20 bg-[#187733] text-white rounded-md mr-6"
                  onClick={ConfirmChange}
                >
                  Ok
                </button>
                <button
                  className="py-4 px-14 bg-[#9B9B9B] text-white rounded-md"
                  onClick={CancelChange}
                >
                  Cancelar
                </button>
              </div>
            </Consulta>
          </div>
        )}
      </div>
    </>
  );
};
export default AprovacaoSubordinado;
