import { useState } from "react";

const Dropdown = ({ userImagem, user }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="flex flex-col">
      <button
        type="button"
        className="flex relative justify-center mr-5 cursor-default m-f1:mr-0"
        onClick={() => setIsVisible(!isVisible)}
        onBlur={() => setIsVisible(false)}
      >
        <div className="flex m-f1:py-2 t-f1:py-1">
          <div className="rounded-full overflow-hidden border-2 relative mr-5 m-f1:w-8 m-f1:h-8 m-f1:mr-3 t-f1:h-12 t-f1:w-12">
            <img
              alt="Sua Foto"
              src={userImagem}
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-full max-w-none"
            />
          </div>
          <div className="flex flex-col text-left justify-center m-f1:hidden t-f1:flex">
            <h5 className="p-0 text-lg font-medium leading-5 whitespace-nowrap text-ellipsis max-w-sm overflow-hidden">
              {user.nome}
            </h5>
            <span className="text-xs whitespace-nowrap max-w-[200px] overflow-hidden text-ellipsis">{user.perfilNome}</span>
          </div>
          <div className="h-full ml-2 flex items-start py-[10px] m-f1:hidden t-f1:flex">
          </div>
        </div>
      </button>
    </div>
  );
};

export default Dropdown;
