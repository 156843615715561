import React from "react";
import Circle from 'react-circle';

const Index = ({ data }) => {
  return (
    <div className="flex h-full justify-around">
      <div className="flex flex-col items-center">
        <Circle
          animate={true}
          animationDuration="1s"
          size="10"
          responsive={true}
          lineWidth="25"
          progressColor="rgb(27, 38, 70)"
          bgColor="#EBEBEB"
          textColor="#1B2646"
          percentSpacing={10}
          roundedStroke={true}
          showPercentage={true}
          showPercentageSymbol={true}
          progress={data.SugestaoEnviadas}
        />
        <div className="font-semibold text-[#1B2646]">Sugestões Enviadas</div>
      </div>
      <div className="flex flex-col items-center">
        <Circle
          animate={true}
          animationDuration="1s"
          size="10"
          responsive={true}
          lineWidth="25"
          progressColor="rgb(27, 38, 70)"
          bgColor="#EBEBEB"
          textColor="#1B2646"
          percentSpacing={10}
          roundedStroke={true}
          showPercentage={true}
          showPercentageSymbol={true}
          progress={data.AvaliadoresConfirmados}
        />
        <div className="font-semibold text-[#1B2646]">Avaliadores Confirmados</div>
      </div>
    </div>
  );
}

export default Index;
