import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";

import Pagination from "../Pagination";
import Filtros from "./Filtros";
import Card from "./Card";
import Loading from "../Loading";
import InformacaoNaoEncontrada from "./InformacaoNaoEncontrada";
import InformacaoNaoCadastrada from "./NenhumaInformacaoCadastrada";
import InformacaoNaoCadastradaConfigImg from "./NenhumaInformacaoCadastradaConfigImg";

const Consulta = ({
  title,
  subtitle,
  colunas,
  filtros,
  adicionarLink,
  adicionarButton,
  onAdd,
  consulta,
  allowSelectLine,
  onCardSelected,
  children,
  checkItemSelection = () => {},
  configImg
}) => {
  const { form, items, pagina, cardOptions, onSearch } = consulta;
  const { reset, getValues } = form;
  
  const [isFiltroVisible, setIsFiltroVisible] = useState(false);

  const toggleFiltro = (isVisible) => {
    if (isFiltroVisible) {
      setTimeout(() => {
        reset();
        onSearch();
      }, 250);
    }
    setIsFiltroVisible(isVisible);
  };

  const hasFiltro = useCallback(() => {
    return (
      Object.values(getValues()).filter((item) => Boolean(item)).length > 0
    );
  }, [getValues]);

  if (consulta.isLoading) return <Loading isVisible={true} />;

  return (
    <div className="w-full d-f2:pt-[50px] d-f2:pl-[40px] flex justify-center">
      <div className="w-[92%] mt-28 d-f2:mt-8 d-f3:mt-28">
        {/* Versão Tablet e Desktop */}
        <div className="flex justify-between m-f1:hidden t-f1:flex">
          <div className="flex items-center">
            <div className="text-[28px] font-semibold mr-4 text-[#1B2646]">
              {title}
              <p class="text-[13px] pl-5">{subtitle != null ? subtitle : ""}</p>
            </div>
            <div>
              {onAdd && (
                  <button
                    className="bg-[#005BBF] text-[#FFF] py-1 px-5 rounded-md hover:bg-[#1E3784]"
                    onClick={onAdd}
                  >
                    Adicionar
                  </button>
                )}
              {adicionarButton ??
                (adicionarLink && (
                  <Link
                    to={adicionarLink}
                    className="bg-[#005BBF] text-[#FFF] py-1 px-5 rounded-md hover:bg-[#1E3784]"
                  >
                    Adicionar
                  </Link>
                ))}
            </div>
          </div>
          {filtros && (
            <div>
              <button
                className="bg-[#005BBF] text-[#FFF] mt-1 py-1 px-10 rounded-md hover:bg-[#1E3784]"
                onClick={() => toggleFiltro(!isFiltroVisible)}
              >
                Filtrar
              </button>
            </div>
          )}
        </div>
        {/* Versão Mobile */}
        <div className="text-[28px] font-semibold mr-4 text-[#1B2646] t-f1:hidden">
          {title}
        </div>
        <div className="flex justify-between mt-3 t-f1:hidden">
          <div className="mt-1">
            {adicionarButton ??
              (adicionarLink && (
                <Link
                  to={adicionarLink}
                  className="bg-[#005BBF] text-[#FFF] py-1 px-5 my-0 rounded-md hover:bg-[#1E3784]"
                >
                  Adicionar
                </Link>
              ))}
          </div>
          {filtros && (
            <div>
              <button
                className="bg-[#005BBF] text-[#FFF] py-1 px-10 rounded-md hover:bg-[#1E3784]"
                onClick={() => toggleFiltro(!isFiltroVisible)}
              >
                Filtrar
              </button>
            </div>
          )}
        </div>
        {filtros && (
          <Filtros
            form={form}
            configuration={filtros}
            isFiltroVisible={isFiltroVisible}
            toggleFiltro={toggleFiltro}
            search={onSearch}
          />
        )}

        {items.records.length > 0 && (
          <h1 className="text-[#1B2646] font-medium mt-10 text-xl">
            Listagem
          </h1>
        )}

        <div className="">
          {items.records.length === 0 &&
            (hasFiltro() ? (
              <InformacaoNaoEncontrada />
            ) : (
              (configImg == null ? <InformacaoNaoCadastrada /> : <InformacaoNaoCadastradaConfigImg configImg={configImg} />)
            ))}

          {items.records.map((item, index) => (
            <Card
              key={index}
              item={item}
              colunas={colunas}
              cardOptions={cardOptions}
              onSearch={onSearch}
              allowSelectLine={allowSelectLine}
              onCardSelected={onCardSelected}
              isSelected={checkItemSelection(item)}
              totalItems={items.recordsTotal}
            />
          ))}
        </div>
          <div className="w-full h-24 flex items-center justify-between">
            <div>{children}</div>
            {items.records.length > 0 && items.recordsTotal > items.recordsFiltered && (
              <Pagination
                pagina={pagina}
                recordsTotal={items.recordsTotal}
                itemsQuantity={items.take}
              />
            )}
          </div>
      </div>
    </div>
  );
};

export default Consulta;
