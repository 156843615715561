
export const downloadAPI = {
  download: async (token) => {
    const file = process.env.REACT_APP_API_URL + '/Download';

    let headers = new Headers();
    headers.append('Token', token);

    const response = await fetch(file, { headers });
    const blobby = await response.blob();
    return blobby;
  },

  downloadWithName: async (token) => {
    const file = process.env.REACT_APP_API_URL + '/Download/DownloadWithName';

    let headers = new Headers();
    headers.append('Token', token);

    const response = await fetch(file, { headers });
    const blobby = await response.blob();
    return blobby;
  },

  downloadIndicationPending: async (token) => {
    const file = process.env.REACT_APP_API_URL + '/Download/DownloadIndicationPending';

    let headers = new Headers();
    headers.append('Token', token);

    const response = await fetch(file, { headers });
    const blobby = await response.blob();
    return blobby;
  }
}
