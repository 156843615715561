export const buttonColor = {
  lightGreen: "bg-[#9B9B9B] text-[#FFF] rounded-md hover:bg-[#B5B2B2]",
  darkGreen: "bg-[#187733] text-[#FFF] rounded-md hover:bg-[#1F803A]",
  darkBlue: "bg-[#005BBF] text-[#FFF] rounded-md",
};

const Button = ({
  color = buttonColor.lightGreen,
  type = "button",
  text,
  children,
  className,
  onClick,
  readOnly,
}) => {
  return (
    <button
      type={type}
      className={`min-w-[157px] px-4 min-h-[44px] flex items-center justify-center ${
        readOnly ? "bg-[#18773328]" : color
      } ${className}`}
      onClick={() => {
        if (!readOnly) onClick?.();
      }}
    >
      {text || children}
    </button>
  );
};

export default Button;
