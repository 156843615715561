import { useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectUserToken } from "../../features/user";
import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import { usuarioApi } from "../../utility/api/usuarioAPI";
import Swal from "sweetalert2";
import Dropdown from "../../components/Dropdown";

const Index = () => {
  const token = useSelector(selectUserToken);

  const ativar = useCallback(
    (item, onSearch) => {
      Swal.fire({
        title: "Tem certeza que deseja ativar?",
        showDenyButton: true,
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (swalResult) => {
        if (swalResult.isConfirmed) {
          let result = await usuarioApi.ativarInativarUsuario(
            item.id,
            true,
            token
          );

          if (result.hasError) {
            Swal.fire({
              title: "Erro!",
              text: "Algo deu errado",
              icon: "error",
            });
          } else {
            onSearch(() => {
              Swal.fire({
                title: "Sucesso!",
                text: "Ativado com sucesso!",
                icon: "success",
              });
            });
          }
        }
      });
    },
    [token]
  );

  const inativar = useCallback(
    (item, onSearch) => {
      Swal.fire({
        title: "Tem certeza que deseja desativar?",
        showDenyButton: true,
        confirmButtonText: "Sim",
        denyButtonText: `Não`,
      }).then(async (swalResult) => {
        if (swalResult.isConfirmed) {
          let result = await usuarioApi.ativarInativarUsuario(
            item.id,
            false,
            token
          );

          if (result.hasError) {
            Swal.fire({
              title: "Erro!",
              text: "Algo deu errado",
              icon: "error",
            });
          } else {
            onSearch(() => {
              Swal.fire({
                title: "Sucesso!",
                text: "Desativado com sucesso!",
                icon: "success",
              });
            });
          }
        }
      });
    },
    [token]
  );
  const options = useMemo(
    () => [
      { id: 0, text: "Ativo" },
      { id: 1, text: "Inativo" },
    ],
    []
  );

  const filtros = [
    {
      label: "Pesquisa",
      placeholder: "Pesquise por qualquer coluna",
      name: "queryNome",
    },
    {
      element: (form) => (
        <Dropdown
          form={form}
          name="queryStatus"
          label="Filtrar por"
          options={options}
          placeholder="Selecione..."
        />
      ),
    },
  ];

  const colunas = [
    {
      label: "Nome",
      prop: "nome",
      width: "12.5%",
    },
    {
      label: "Perfil",
      prop: "perfilNome",
      width: "12.5%",
    },
    {
      label: "E-mail",
      prop: "email",
      width: "20%",
    },
    {
      label: "Setor",
      prop: "setor",
      width: "12.5%",
    },
    {
      label: "Unidade",
      prop: "unidade",
      width: "10%",
    },
    {
      label: "Divisão",
      prop: "unidade",
      width: "10%",
    },
    {
      label: "Cargo",
      prop: "cargo",
      width: "12.5%",
    },
    {
      label: "Superior",
      prop: "superior",
      width: "10%",
    },
  ];

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: usuarioApi.listar,
      },
    };
  }, []);

  const cardOptions = useMemo(() => {
    return {
      config: {
        hasAlert: (item) => item.hasAlert,
      },
      // excluir: {
      //     label: "Excluir",
      //     questionText: "Tem certeza que deseja excluir?",
      //     successText: "Sucesso",
      //     fetchFunction: (id, token) => usuarioApi.excluir(id, token),
      // },
      ativar: {
        label: "Ativar",
        isVisible: (item) => item.status === "Inativo",
        action: ativar,
      },
      inativar: {
        label: "Inativar",
        isVisible: (item) => item.status === "Ativo",
        action: inativar,
      },
    };
  }, [ativar, inativar]);

  const consulta = useConsulta(token, events, cardOptions);



  return (
    <div className="d-f0:pl-10 d-f2:pl-0">
      <Consulta
        title="Usuários"
        colunas={colunas}
        filtros={filtros}
        consulta={consulta}
      />
    </div>
  );
};

export default Index;
