import React from "react";

import Logo from "../../../assets/png/LogoAvaliadores.png";

const MenuHeader = () => {
  return (
    <div className="py-2 pl-4">
      <img className="h-full" alt="Logo SCI" src={Logo} />
    </div>
  );
};

export default MenuHeader;
