import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: "",
  user: {}
};

const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userloggedIn(state, action) {
      return {
        token: action.payload.token,
        user: action.payload.jwt
      };
    },
    userLoggedOut(state, action) {
      return {};
    },
  },
});

export const { userloggedIn, userLoggedOut } = usersSlice.actions;

export const selectUserToken = (state) => state.user.token;

export const selectUser = (state) => state.user.user;

export const selectPermissions = (state) => state.user.user.permissao;

export const selectUserId = (state) => state.user.id;

export const userProfile = {
  Diretor: 0,
  Administrador: 1,
  Avaliado: 2,
  Aprovador: 3,
  Ambos: 4
}

export default usersSlice.reducer;
