
export const uploadAPI = {
  uploadXLS: (file, token) => {
    var myHeaders = new Headers();
    myHeaders.append("Token", token);

    var formdata = new FormData();
    formdata.append("file", file, "Modelo-Exemplo-RH.xlsx");

    return fetch(
      `${process.env.REACT_APP_API_URL}/Upload/UploadXLS`,
      {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      }
    );
  },
};
