import { NavLink } from "react-router-dom";
const ContainerModulo = ({ children, onClick, link, isNewTab }) => {
  return (
    <div
      className="w-full flex justify-center cursor-pointer"
      onClick={onClick}
    >
      <NavLink
        to={`${link}`}
        target={isNewTab ? "_blank" : undefined}
        rel={isNewTab ? "noopener noreferrer" : undefined}
        className="w-[90%] h-16 bg-modules flex items-center"
      >
        {children}
      </NavLink>
    </div>
  );
};
export default ContainerModulo;
