import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectUserToken } from "../../features/user";
import Consulta from "../../components/Consulta";
import useConsulta from "../../components/Consulta/useConsulta";
import { usuarioApi } from "../../utility/api/usuarioAPI";
import { useForm } from "react-hook-form";
import { NavLink, useParams } from "react-router-dom";
import Header from "../../routes/Layout/Header";

const VerAvaliadoresSugeridos = () => {
  const token = useSelector(selectUserToken);
  const form2 = useForm();

  const filtros = [
    {
      label: "Nome",
      placeholder: "Pesquise por qualquer coluna",
      name: "queryNome",
    },
  ];

  const columns = [
    {
      label: "Subordinado",
      prop: "subordinado",
      width: "20%",
    },
    {
      label: "Sugestão do Avaliador",
      prop: "sugestaoAvaliador",
      width: "20%",
    },
    {
      label: "Setor",
      prop: "setor",
      width: "10%",
    },
    {
      label: "Unidade",
      prop: "unidade",
      width: "10%",
    },
    {
      label: "Qtde de Sugestão",
      prop: "qtdeSugestao",
      width: "15%",
    },
    {
      label: "Qtde de Avalição Confirmada",
      prop: "qtdeAvaliacaoConfirmada",
      width: "25%",
    },
  ];

  const params = useParams();

  const aprovacaoSubordinados = useCallback(
    async (filtros, token) => {
      return await usuarioApi.aprovacaoSubordinados(
        { ...filtros, subordinadoId: params.id },
        token
      );
    },
    [params.id]
  );

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: aprovacaoSubordinados,
      },
    };
  }, [aprovacaoSubordinados]);

  const cardOptions = useMemo(() => {
    return {
      config: {
        hasAlert: (item) => item.hasAlert,
        isVisible: false,
      },
    };
  }, []);

  const consulta = useConsulta(token, events, cardOptions);

  return (
    <>
      <Header />
      <div className="flex flex-col items-center">
        <Consulta
          title="Detalhes da aprovação"
          colunas={columns}
          filtros={filtros}
          consulta={consulta}
        />
        <div className="w-[92%] mt-8 text-right">
          <NavLink
            className="py-5 px-20 text-white rounded-md bg-[#9b9b9b]"
            to={`/AprovacaoSubordinado`}
          >
            Voltar
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default VerAvaliadoresSugeridos;
