import Swal from "sweetalert2";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import Button from "../../components/Button";
import Header from "../../routes/Layout/Header";
import Consulta from "../../components/Consulta";
import { selectUserToken } from "../../features/user";
import { usuarioApi } from "../../utility/api/usuarioAPI";
import useConsulta from "../../components/Consulta/useConsulta";

const AprovacaoSubordinado = () => {
  const token = useSelector(selectUserToken);
  const navigate = useNavigate();
  const form2 = useForm();

  const filtros = [
    {
      label: "Nome",
      placeholder: "Pesquise por qualquer coluna",
      name: "queryNome",
    },
  ];

  const colunas = [
    {
      label: "Nome",
      prop: "nome",
      width: "25%",
    },
    {
      label: "Setor",
      prop: "setor",
      width: "25%",
    },
    {
      label: "Divisão",
      prop: "divisao",
      width: "25%",
    },
  ];

  let events = useMemo(() => {
    return {
      onSearch: {
        fetchFunction: usuarioApi.subordinados,
      },
    };
  }, []);
  const cardOptions = useMemo(() => {
    return {
      config: {
        hasAlert: (item) => item.hasAlert,
        isVisible: false,
      },
    };
  }, []);

  const consulta = useConsulta(token, events, cardOptions);
  const { items } = consulta;

  const [itemsSelected, setItemsSelected] = useState([]);
  const [isConfirmationMode, setConfirmationMode] = useState(false);

  window.itemsSelected = itemsSelected;

  const onCardSelected = (item, isSelected) => {
    if (isSelected) {
      setItemsSelected([item]);
    } else {
      const myArray = itemsSelected.filter(function (obj) {
        return obj.id !== item.id;
      });
      setItemsSelected(myArray);
    }
  };

  const GoToDetails = () => {
    if (!itemsSelected || itemsSelected.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Seleção inválida",
        text: `Selecione uma sugestão de avaliadores para visualizar os detalhes.`,
      });

      return;
    }

    navigate(`/ver-avaliadores-sugeridos/${itemsSelected[0].id}`);
  };

  const GoToAprove = async () => {
    if (!itemsSelected || itemsSelected.length === 0) {
      Swal.fire({
        icon: "error",
        title: "Seleção inválida",
        text: `Selecione uma sugestão de avaliadores para seguir com a aprovação.`,
      });

      return;
    }

    if (itemsSelected[0].status === "Aprovado") {
      Swal.fire({
        icon: "error",
        title: "Seleção inválida",
        text: `Não é possível seguir com aprovação de subordinado já aprovado.`,
      });

      return;
    }

    navigate(`/AprovacaoSubordinadoSelecionado/${itemsSelected[0].id}`);
  };

  const sendData = () => { };

  const GoBackSelection = () => {
    setConfirmationMode(false);
  };

  if (!isConfirmationMode && items.recordsTotal >= 0) {
    return (
      <>
        <Header />
        <div className="flex flex-col">
          <Consulta
            title="Aprovação dos Subordinados"
            colunas={colunas}
            filtros={filtros}
            consulta={consulta}
            allowSelectLine={true}
            onCardSelected={onCardSelected}
            checkItemSelection={(item) =>
              itemsSelected.find((i) => i.id === item.id) != null
            }
          />
          <div className="w-[92%] mt-4 flex mx-auto d-f2:pl-[40px]">
            <Button
              readOnly={
                itemsSelected.length === 0 ||
                itemsSelected.at(0)?.status !== "Pendente"
              }
              color="bg-[#187733]"
              className="py-5 px-20 text-white rounded-md mr-6"
              onClick={GoToAprove}
            >
              Visualizar Sugestão
            </Button>
            <Button
              readOnly={
                itemsSelected.length === 0 ||
                itemsSelected.at(0)?.status !== "Aprovado"
              }
              color="bg-[#9b9b9b]"
              className="py-5 px-20 text-white rounded-md"
              onClick={GoToDetails}
            >
              Detalhar Sugestão
            </Button>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="flex flex-col items-center">
        <Consulta
          title="Escolha sua sugestão de avaliadores..."
          colunas={colunas}
          consulta={{
            isLoading: false,
            form: form2,
            items: {
              records: itemsSelected,
            },
            pagina: 0,
            onSearch: consulta.onSearch,
            cardOptions: {
              config: {
                hideStatus: true,
              },
            },
          }}
          allowSelectLine={false}
        />

        {items.recordsTotal > 0 && (
          <div className="w-[92%] mt-4 flex mx-auto d-f2:pl-[40px]">
            <Button
              readOnly={
                itemsSelected.length || itemsSelected[0].status !== "Pendente"
              }
              color="bg-[#187733]"
              className={`py-y px-20 text-white rounded-md mr-6`}
              onClick={sendData}
            >
              Aprovar Sugestão
            </Button>
            <Button
              readOnly={
                itemsSelected.length || itemsSelected[0].status !== "Aprovado"
              }
              color="bg-[#9B9B9B]"
              className="py-y px-20 text-white rounded-md"
              onClick={GoBackSelection}
            >
              Detalhar Sugestão
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
export default AprovacaoSubordinado;
