import { useSelector } from "react-redux";
import { selectUserToken } from "../features/user";
import useFetch from "./useFetch";
import { utilAPi } from "../utility/api/utilAPI";

export const useSetor = () => {
    const token = useSelector(selectUserToken);

    return useFetch({
        endpoint: utilAPi.getSetor
    });
};