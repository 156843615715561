import iconCloseMenu from "../../../assets/png/chevron-double-left.png";

const HeaderMenu = ({ closeMenu }) => {
  return (
    <button className="mt-4 mb-12 h-6 flex flex-row w-full justify-end pr-6" onClick={closeMenu}>
      <div className="w-6">
        <img alt="Fechar Menu" src={iconCloseMenu} />
      </div>
    </button>
  );
};

export default HeaderMenu;
