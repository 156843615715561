import React from "react";
import { useSelector } from "react-redux";

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import Home from "./routes/Home";
import Pendencia from "./routes/Pendencia";
import Login from "./routes/Login";
import Usuario from "./routes/Usuarios";
import Layout from "./routes/Layout";
import Logout from "./routes/Logout";

import NotFound from "./routes/NotFound";

import AvaliadoAprovador from "./routes/Acesso/AvaliadoAprovador";
import Avaliado from "./routes/Avaliado";
import SugestaoAvaliadores from "./routes/SugestaoAvaliadores";
import AprovacaoSubordinado from "./routes/AprovacaoSubordinado";
import AprovacaoSubordinadoSelecionado from "./routes/AprovacaoSubordinadoSelecionado";
import VerAvaliadoresSugeridos from "./routes/VerAvaliadoresSugeridos";
import VisualizarDadosCarregados from "./routes/DadosCarregados";
import ImportExcel from "./routes/ImportExcel";
import { PrivateRoute } from "./utility/authorization";
import { userProfile } from "./features/user";

import { selectUser } from "./features/user";

const Private = ({ children }) => {
  const isAuthenticated = useSelector(selectUser);
  if (!isAuthenticated) return <Navigate to="/login" />;
  return children;
};

const AppRoutes = () => {
  let isDevelopment =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  let baseName = isDevelopment ? "" : process.env.PUBLIC_URL;

  return (
    <Router basename={baseName}>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route exact path='/AvaliadoAprovador' element={<PrivateRoute roles={[userProfile.Avaliado, userProfile.Aprovador, userProfile.Ambos]} />}>
            <Route path="/AvaliadoAprovador" element={<AvaliadoAprovador />} />
        </Route>
        <Route exact path='/Avaliado' element={<PrivateRoute roles={[userProfile.Avaliado, userProfile.Ambos]} />}>
            <Route exact path='/Avaliado' element={<Avaliado />}/>
        </Route>
        <Route exact path='/SugestaoAvaliadores' element={<PrivateRoute roles={[userProfile.Avaliado, userProfile.Ambos]} />}>
            <Route path="/SugestaoAvaliadores" element={<SugestaoAvaliadores />} />
        </Route>

        <Route exact path='/AprovacaoSubordinado' element={<PrivateRoute roles={[userProfile.Aprovador, userProfile.Ambos]} />}>
            <Route path="/AprovacaoSubordinado" element={<AprovacaoSubordinado />} />
        </Route>
        <Route exact path='/AprovacaoSubordinadoSelecionado/:id' element={<PrivateRoute roles={[userProfile.Aprovador, userProfile.Ambos]} />}>
            <Route path="/AprovacaoSubordinadoSelecionado/:id" element={<AprovacaoSubordinadoSelecionado />} />
        </Route>
        <Route exact path='/ver-avaliadores-sugeridos/:id' element={<PrivateRoute roles={[userProfile.Aprovador, userProfile.Ambos]} />}>
            <Route path="/ver-avaliadores-sugeridos/:id" element={<VerAvaliadoresSugeridos />} />
        </Route>
        
        <Route path="/" element={<Private><Layout /></Private>}>
          <Route exact path='/home' element={<PrivateRoute roles={[userProfile.Administrador]} />}>
              <Route exact path="/home" element={<Home />} />
          </Route>
          <Route exact path='/documento/importar' element={<PrivateRoute roles={[userProfile.Administrador]} />}>
              <Route path="/documento/importar" element={<ImportExcel />} />
          </Route>
          <Route exact path='/pendencia/consulta' element={<PrivateRoute roles={[userProfile.Administrador]} />}>
              <Route path="/pendencia/consulta" element={<Pendencia />} />
          </Route>
          <Route exact path='/usuario/consulta' element={<PrivateRoute roles={[userProfile.Administrador]} />}>
              <Route path="/usuario/consulta" element={<Usuario />} />
          </Route>
          <Route exact path='/usuario/dados' element={<PrivateRoute roles={[userProfile.Administrador]} />}>
              <Route path="/usuario/dados" element={<VisualizarDadosCarregados />} />
          </Route>          
          <Route path="/logout" element={<Logout />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};
export default AppRoutes;
