import api from "./index";

export const processoAPI = {
    inicializar: async (token) => {
        return await api.get("/Processo/Inicializar", '', token);
    },
    dadosCarregados: async (data, token) => {
        return await api.post("/Processo/Inicializar", data, token);
    },
    visualizarCarregados: async (data, token) => {
        return await api.post("/Processo/DadosCarregados", data, token);
    },
    excluir: async (token) => {
        return await api.delete("/Processo/Excluir", '', token);
    }
}