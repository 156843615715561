import MenuHeader from "./MenuHeader";
import UserInfo from "./UserInfo";

const Header = () => {
  return (
    <div className="fixed flex top-0 left-0 w-full shadow justify-between z-20 bg-white h-14">
      <MenuHeader />
      <UserInfo />
    </div>
  );
};
export default Header;
