import api from "./index";

export const usuarioApi = {
  listar: async (data, token) => {
    let queryStatus = undefined;

    if (data.hasOwnProperty("queryStatus") && Number.isInteger(data.queryStatus))
      queryStatus = data.queryStatus === 1;

    return api.get(
      `/Usuario/Todos`,
      {
        ...data,
        setorId: data.setorId ? data.setorId : null,
        queryStatus,
      },
      token
    );
  },
  excluir: async (id, token) => {
    return await api.delete(`/Usuario/${id}`, null, token);
  },
  excluirAvaliador: async (data, token) => {
    return await api.delete(`/Usuario/ExcluirAvaliador`, data, token);
  },
  avaliadores: async (data, token) => {
    return await api.post(`/Usuario/Avaliadores`, data, token);
  },
  sugestaoAvaliador: async (data, token) => {
    return await api.post(`/Usuario/SugestaoAvaliadores`, data, token);
  },
  subordinados: async (data, token) => {
    return await api.post(`/Usuario/Subordinados`, data, token);
  },
  aprovarSubordinado: async (data, token) => {
    var result = await api.put(`/Usuario/AprovarSubordinados`, data, token);
    return result;
  },
  aprovacaoSubordinados: async (data, token) => {
    return await api.post(`/Usuario/AprovacaoSubordinados`, data, token);
  },
  escolherAvaliadores: async (data, token) => {
    return await api.post(`/Usuario/EscolherAvaliadores`, data, token);
  },
  substituirAvaliador: async (data, token) => {
    const r = await api.post(`/Usuario/SubstituirAvaliador`, data, token);
    return r;
  },
  substituirAvaliadorConfirm: async (data, token) => {
    return await api.put(`/Usuario/SubstituirAvaliador`, data, token);
  },
  sugestaoDetalhes: async (usuarioId, token) => {
    return await api.get(`/Usuario/SugestaoDetalhes/${usuarioId}`, "", token);
  },
  ativarInativarUsuario: async (usuarioId, isActive, token) => {
    return await api.put(
      `/Usuario/AtivarOuDesativar/${usuarioId}`,
      { ativo: isActive },
      token
    );
  },
};
