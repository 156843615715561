import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../features/user';

export const PrivateRoute = ({ element: Element, roles, path }) => {
    const currentUser = useSelector(selectUser);

    //console.log('Private Route', currentUser);

    if (!currentUser) {
        //console.log('User Not Logged!');
        return <Navigate to="/login" />;
    }

    //console.log('User Role Logged', currentUser.perfilNome);
    //console.log('Roles Route', roles);

    const isNotRoleAuthorized = roles && roles.indexOf(currentUser.perfil) === -1;

    // check if route is restricted by role
    if (isNotRoleAuthorized) {
        //console.log('Access Denied! ' + path);
        // role not authorised so redirect to home page
        return <Navigate to="/login" />;
    }

    //console.log('Access Authorized! ' + path);

    return <Outlet />;
}